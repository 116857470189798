import React from 'react';
import DeutcheImage from "../../../assets/images/deutcheImage.png";

const buySide = [
    {id: 1, image: DeutcheImage},
    {id: 2, image: DeutcheImage},
    {id: 3, image: DeutcheImage},
    {id: 4, image: DeutcheImage},
    {id: 5, image: DeutcheImage},
    {id: 6, image: DeutcheImage},
    {id: 7, image: DeutcheImage},
    {id: 8, image: DeutcheImage},
    {id: 9, image: DeutcheImage},
    {id: 10, image: DeutcheImage},
]
export default function BuysideSection() {
    return (
        <div className='cursor-pointer flex flex-wrap lg:justify-start justify-center items-center gap-[25px]'>
            {
                buySide.map((event, index) => {
                    return(
                        <div className="bg-white border-[0.58px] border-solid border-[#E5E5E5] py-[25px] px-[35px]" key={event.id}>
                            <img src={event.image}/>
                        </div>
                    )
                })
            }
        </div>
    );
}