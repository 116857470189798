import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../common/Button';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import AuthApi from '../../../api/AuthApi';
import ErrorMessage from '../../common/ErrorMessage';
import logo from '../../../assets/svgs/logo.svg';
import TextInput from '../../common/TextInput';
import { CheckCircleIcon } from '@heroicons/react/solid';

interface IForgotPasswordForm {
    email: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Please enter your email.'),
});

export default function ForgotPassword() {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string|undefined>(undefined);

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 pb-20">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={logo}
                    alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot Password</h2>
                <p className="mt-4 mx-4 text-center text-sm text-gray-600">
                    Return to{' '}
                    <Link className="text-blue-600 hover:text-blue-500 font-medium" to='/login' replace={true}>Login</Link>
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mb-20">
                    {
                        isSuccess
                        ?
                        <div className='flex'>
                            <CheckCircleIcon className='w-20 h-20 text-green-500 mr-4'/>
                            <p className='my-auto'>Sent! Check your email for a link to reset your password.</p>
                        </div>
                        :
                        <div>
                            <ErrorMessage error={submitError} />
                            <Formik
                                initialValues={{
                                    email: ''
                                }}
                                onSubmit={async (values: IForgotPasswordForm, actions) => {
                                    try {
                                        await AuthApi.forgotPassword(values);
                                        setIsSuccess(true);
                                    } catch(err: any) {
                                        actions.setSubmitting(false);
                                        setSubmitError(err && err.message ? err.message : 'Something went wrong, please try again.');
                                    }
                                }}
                                validateOnBlur={true}
                                validationSchema={validationSchema}
                            >
                                {(props: FormikProps<IForgotPasswordForm>) => {
                                    const {
                                        values, 
                                        errors, 
                                        touched, 
                                        isSubmitting, 
                                        handleChange, 
                                        handleBlur
                                    } = props;

                                    return (
                                        <Form className='space-y-6'>
                                            <TextInput 
                                                label="Email"
                                                name="email"
                                                labelClass='block text-sm font-medium text-gray-700 mb-1'
                                                childClass='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                error={!!(errors.email && touched.email)}
                                                errorMessage={errors.email}
                                                value={values.email}
                                            />

                                            <div>
                                                <Button 
                                                    type='submit'
                                                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-0'
                                                    disabled={isSubmitting}
                                                    children='Send Reset Email'
                                                />
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    }
                    
                </div>
            </div>
        </div>
    )
}
