import React from 'react';
interface Props {}

export default function SampleDealPreview({}: Props) {
  return (
    <div className="flex flex-wrap justify-center items-center gap-6 md:gap-12 xl:gap-16">
      <div className="w-full md:w-auto text-center md:text-left">
        <button className="pointer-events-none inline-block group relative w-72 max-w-full overflow-hidden p-5 select-none bg-white border border-transparent hover:border-primary rounded-4xl shadow-dark-lg hover:shadow transition-all duration-300">
          <img
            src={'https://trofied.s3.amazonaws.com/trophies/sample.png'}
            alt="Deal Image"
            className="aspect-square p-12 object-center object-contain bg-white border border-light-300 rounded-xl"
          />
        </button>
      </div>

      <div className="flex-1 md:max-w-[445px]">
        <span className="absolute inset-0 flex justify-center items-center bg-light-100 bg-opacity-60"></span>

        <div className="relative">
          <ul className="divide-y divide-light-300">
            <li className="py-2 flex justify-between gap-5">
              <span className="text-sm text-secondary">Company</span>
              <p className="text-sm font-semibold text-right"></p>
            </li>

            <li className="py-2 flex justify-between gap-5">
              <span className="text-sm text-secondary">Seller</span>
              <p className="text-sm font-semibold text-right"></p>
            </li>

            <li className="py-2 flex justify-between gap-5">
              <span className="text-sm text-secondary">Buyer</span>
              <p className="text-sm font-semibold text-right"></p>
            </li>

            <li className="py-2 flex justify-between gap-5">
              <span className="text-sm text-secondary">Amount</span>
              <p className="text-sm font-semibold text-right"></p>
            </li>

            <li className="py-2 flex justify-between gap-5">
              <span className="text-sm text-secondary">Transaction</span>
              <p className="text-sm font-semibold text-right"></p>
            </li>

            <li className="py-2 flex justify-between gap-5">
              <span className="text-sm text-secondary">Closed</span>
              <p className="text-sm font-semibold text-right"></p>
            </li>
          </ul>

          <h6 className="h4 font-semibold absolute inset-0 flex justify-center items-center bg-light-100 bg-opacity-60">
            No Trofies Yet!
          </h6>
        </div>
      </div>
    </div>
  );
}
