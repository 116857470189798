import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import React from "react";
import { useTable, usePagination, Row } from "react-table";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

interface Column {
  Header: string;
  accessor?: string;
  Cell?: Function;
}
export interface ColumnGroup {
  id: string;
  Header?: string;
  columns: Column[];
}

export interface TableProps {
  columns: ColumnGroup[];
  data: object[];
}

export default function Table(props: TableProps) {
  const { columns, data } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // if (page.length < pageSize) {
  //     while(page.length < pageSize) {
  //         page.push({})
  //     }
  // }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200"
              {...getTableProps()}
            >
              <thead className="bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-gray-200"
                {...getTableBodyProps()}
              >
                {page.map((row: Row<object>, i: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-6 py-4 whitespace-nowrap"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination flex justify-between my-2">
              <div className="ml-8 my-auto">
                <p className="flex text-sm text-gray-700 align-middle  my-auto">
                  Showing
                  <span className="font-medium mx-1">
                    {1 + pageSize * pageIndex}
                  </span>
                  to
                  <span className="font-medium mx-1">
                    {Math.min(data.length, pageSize * (pageIndex + 1))}
                  </span>
                  of
                  <span className="font-medium mx-1">{data.length}</span>
                  results
                </p>
              </div>
              <div className="flex justify-center text-gray-700">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <ArrowCircleLeftIcon className="h-6 w-6 cursor-pointer" />
                </button>
                <p className="text-sm mx-2 my-auto">
                  Page
                  <span className="font-medium mx-1">{pageIndex + 1}</span>
                  of
                  <span className="font-medium mx-1">
                    {Math.ceil(data.length / pageSize)}
                  </span>
                </p>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <ArrowCircleRightIcon className="h-6 w-6 cursor-pointer" />
                </button>
              </div>

              <div className="flex mr-8 my-auto">
                <TextInput
                  name="page"
                  label="Go to page "
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  type="number"
                  parentClass="my-auto text-gray-700 mr-2"
                  childClass="my-auto w-16 h-10 px-3 text-sm placeholder-gray-600 border border-slate-400 rounded-lg focus:shadow-outline"
                  labelClass="text-sm text-gray-700 mr-2"
                />
                <SelectInput
                  name="page-size"
                  parentClass="my-auto mb-auto text-gray-700"
                  childClass="h-10 w-15 text-sm placeholder-gray-600 border border-slate-400 rounded-lg focus:shadow-outline"
                  labelClass="text-sm text-gray-700 mr-2"
                  options={[
                    { label: "Show 10", value: 10 },
                    { label: "Show 15", value: 15 },
                    { label: "Show 25", value: 25 },
                    { label: "Show 50", value: 50 },
                  ]}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
