import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import UserProfileApi from '../../../api/UserProfileApi';
import ProfileInfo from './ProfileInfo';
import EditProfileForm from './EditProfileForm';
import DealSection from './DealSection';
import Loading from '../../common/Loading';
import { Transition } from '@headlessui/react';

import IDeal from 'shared/src/model-interfaces/IDeal';

export default function UserProfile() {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { isLoading, data } = useQuery(
    'my-profile',
    UserProfileApi.getMyProfile
  );

  if (data && data?.data?.deals) {
    let deals: Partial<IDeal>[] = data?.data?.deals;
    let index = 1;
    while (deals.length < 12) {
      deals.push({
        id: -index,
        image: `trophies/trofy${index}.png`,
      });
      index++;
    }
  }

  useEffect(() => {
    if (localStorage.getItem('loadTimeout') === 'true') {
      setTimeout(() => {
        setIsOpen(false);
        localStorage.setItem('loadTimeout', 'false')
      }, 3000);
    } else {
      setIsOpen(isLoading);
    }
  }, [isLoading]);

  // console.log('isLoading', isLoading, data, data?.data?.deals?.length);

  // if (isLoading) {
  //   return <Loading />;
  // }

  // if (!data || !data.data) {
  //   return <p>Failed to load Profile</p>;
  // }

  return (
    <div className="relative">
      <Transition
        show={isOpen}
        className="bg-white absolute inset-0 -bottom-4 min-h-screen z-[1]"
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Loading />
      </Transition>

      {!data || !data.data ? (
        <p>Failed to load Profile</p>
      ) : (
        <>
          <section className="relative">
            <div className="container-3">
              <div className="flex flex-wrap items-start">
                <div className="w-full lg:w-[320px] xl:w-[400px] 2xl:w-[400px] 3xl:[w-450px] lg:sticky lg:top-0 lg:h-screen">
                  <ProfileInfo
                    length={data.data.deals.length}
                    dataProfile={data.data.profile}
                    onEdit={() => setEditModalOpen(true)}
                  />
                </div>

                <div className="w-full lg:w-auto flex-1">
                  <DealSection deals={data.data.deals} />
                </div>
              </div>
            </div>
          </section>

          <EditProfileForm
            open={editModalOpen}
            setOpen={setEditModalOpen}
            userId={data.data.id}
            initialValues={data.data.profile}
          />
        </>
      )}
    </div>
  );
}
