import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserProfile from './components/pages/UserProfile/index';
import { AuthProvider } from './context/AuthContext';
import RequireAuth from './lib/RequireAuth';
import DealProfile from './components/pages/DealProfile';
import AdminPanel from './admin';
import Users from './admin/pages/Users';
import AdminDashboard from './admin/pages/AdminDashboard';
import Companies from './admin/pages/Companies';
import Root from './components/pages';
import ComingSoon from './components/pages/ComingSoon';
import {
  TermsAndConditions,
  PrivacyPolicy,
  Disclaimer,
} from './components/common/Termly';
import Contact from './components/pages/Contact';
import Login from './components/pages/Auth/Login';
import Settings from './components/pages/Settings';
import Wallet from './components/pages/Wallet';
import ForgotPassword from './components/pages/Auth/ForgotPassword';
import ResetPassword from './components/pages/Auth/ResetPassword';
import CompanyProfile from './components/pages/CompanyProfile';
import User from './components/pages/User/index';
import Trofies from './admin/pages/AdminTrofies';
import Signup from './components/pages/Auth/Signup';
import Explore from './components/pages/explore';
import Landing from './components/pages/Landing';
import ScrollToTop from './ScrollToTop';
import SendMessageContact from './components/pages/Contact/SendMessageContact';
import BasicAuth from './lib/BasicAuth';

// Create a client
const queryClient = new QueryClient();

// MUI theme
const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <QueryClientProvider client={queryClient}>
          {/* <BasicAuth> */}
          <AuthProvider>
            <ToastContainer position="top-right" />
            <Routes>
              <Route path="/" element={<Root />}>
                <Route
                  path="case"
                  element={
                    <RequireAuth>
                      <UserProfile />
                    </RequireAuth>
                  }
                />

                <Route
                  path="feed"
                  element={
                    <RequireAuth>
                      <User />
                    </RequireAuth>
                  }
                />

                <Route
                  path="settings"
                  element={
                    <RequireAuth>
                      <Settings />
                    </RequireAuth>
                  }
                />

                <Route
                  path="wallet"
                  element={
                    <RequireAuth>
                      <Wallet />
                    </RequireAuth>
                  }
                />

                <Route path="explore" element={<Explore />} />

                <Route path="coming-soon" element={<ComingSoon />} />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="disclaimer" element={<Disclaimer />} />
                <Route path="contact" element={<Contact />} />
                <Route path="send" element={<SendMessageContact />} />
                <Route path="case/deal/:dealId" element={<DealProfile />} />
                <Route
                  path="/company/:companyId"
                  element={<CompanyProfile />}
                />
                <Route path="/home" element={<Landing />} />
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="admin" element={<AdminPanel />}>
                <Route
                  path="dashboard"
                  element={
                    <RequireAuth roles={['admin']}>
                      <AdminDashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="users"
                  element={
                    <RequireAuth roles={['admin']}>
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route
                  path="companies"
                  element={
                    <RequireAuth roles={['admin']}>
                      <Companies />
                    </RequireAuth>
                  }
                />
                <Route
                  path="trofies"
                  element={
                    <RequireAuth roles={['admin']}>
                      <Trofies />
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </AuthProvider>
          {/* </BasicAuth> */}
          {/*<ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
