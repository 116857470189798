import React, { useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import TextField from '../../../components/common/TextInput';
import SelectInput from '../../../components/common/SelectInput';
import states from '../../../lib/constants/States.constant';
import ErrorMessage from '../../../components/common/ErrorMessage';
import FileDrop from '../../../components/common/FileDrop';
import CompaniesApi from '../../../api/CompaniesApi';
import { ICompaniesApi } from 'shared/src/api-interfaces/ICompaniesApi';
import SlideOver from '../../../components/common/SlideOver';
import Loading from '../../../components/common/Loading';

interface IEditCompanyForm {
    name: string;
    slug?: string;
    city?: string;
    state?: string;
    employeeCount?: number;
    logo?: string;
    bannerImage?: string;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    slug: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    employeeCount: Yup.string().nullable(),
    logo: Yup.string().nullable(),
    bannerImage: Yup.number().nullable()
});

interface Props {
    open: boolean;
    companyId: number;
    setOpen: (open: boolean) => void;
}

export default function EditCompanyForm({ companyId, open, setOpen }: Props) {
    const [submitError, setSubmitError] = useState<string|undefined>(undefined);
    const queryClient = useQueryClient();
    const editCompany = useMutation(
        (data: ICompaniesApi.Update.Request) => CompaniesApi.updateCompany(companyId, data), 
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`company:${companyId}`);
                queryClient.invalidateQueries(`list-companies`);
                setOpen(false);
            },
            onError: (err: any) => {
                console.log(err);
                setSubmitError(err && err.message ? err.message : 'Something went wrong, please try again.');
            }
        }
    );

    const {
        isLoading,
        data,
    } = useQuery(`company:${companyId}`, () => CompaniesApi.getCompany(companyId));

    return (
        <SlideOver open={open} setOpen={setOpen} title='Edit Company'>
            {
                isLoading
                ?
                <Loading />
                :
                !data || !data.data
                ?
                <p>Something went wrong. PLease try again</p>
                :
                <div className='relative flex-1 h-full'>
                    <ErrorMessage error={submitError} />
                    <Formik
                        initialValues={data.data}
                        onSubmit={async (values, actions) => {
                            await editCompany.mutateAsync(values);
                            actions.setSubmitting(false);
                        }}
                        validateOnBlur={true}
                        validationSchema={validationSchema}
                    >
                        {(props: FormikProps<IEditCompanyForm>) => {
                            const {
                                values, 
                                errors, 
                                touched, 
                                isSubmitting,
                                handleChange, 
                                handleBlur,
                                setFieldValue
                            } = props;

                            return (
                                <Form className='h-full flex flex-col justify-between'>
                                    <div className=" bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <TextField 
                                            label="Name"
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!(errors.name && touched.name)}
                                            errorMessage={errors.name}
                                            value={values.name}
                                        />
                                        <TextField 
                                            label="Profile URL Path"
                                            name="slug"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!(errors.slug && touched.slug)}
                                            errorMessage={errors.slug}
                                            value={values.slug}
                                        />
                                        <div className="flex space-y-4 md:space-y-0">
                                            <TextField 
                                                label="City"
                                                name="city"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!(errors.city && touched.city)}
                                                errorMessage={errors.city}
                                                value={values.city}
                                                childClass=' mr-2'
                                                parentClass={'px-2 w-4/5 shrink mr-2'}
                                            />
                                            <SelectInput 
                                                label="State"
                                                name="state"
                                                options={
                                                    states.map(s => {
                                                        return {
                                                            label: s,
                                                            value: s
                                                        }
                                                    })
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!(errors.state && touched.state)}
                                                errorMessage={errors.state}
                                                value={values.state}
                                                parentClass={'px-2 flex-auto'}
                                            />
                                        </div>
                                        <TextField 
                                            label="Employee Count"
                                            name="employeeCount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!(errors.employeeCount && touched.employeeCount)}
                                            errorMessage={errors.employeeCount}
                                            value={values.employeeCount}
                                        />
                                        <FileDrop 
                                            label='Logo'
                                            uploadPrefix={`companies/logos`}
                                            uploadName='logo' 
                                            onUpload={(uploadPath: string) => setFieldValue('logo', uploadPath)}
                                        />
                                        <FileDrop 
                                            label='Banner Image'
                                            uploadPrefix={`companies/banners`}
                                            uploadName='bannerImage' 
                                            onUpload={(uploadPath: string) => setFieldValue('bannerImage', uploadPath)}
                                        />
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse bottom-0">
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            }
            
        </SlideOver>
    )
}
