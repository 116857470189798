import axios from "axios";
import FormData from "form-data";

const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINATA_SECRET;
const jwt = process.env.REACT_APP_PINATA_JWT;

interface metadata {
  name?: string;
  image?: string;
  description?: string;
  count?: string;
}

export default class Pinata {
  public static async pinJSONToIPFS(JSONBody: metadata) {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    var data = JSON.stringify({
      pinataMetadata: {
        name: JSONBody.name + " trofy",
      },
      pinataContent: JSONBody,
    });
    var data = JSON.stringify({
      pinataOptions: {
        cidVersion: 1,
      },
      pinataMetadata: {
        name: JSONBody.name + " trofy",
      },
      pinataContent: {
        name: JSONBody.name,
        image: JSONBody.image,
        description: JSONBody.description,
        count: JSONBody.count,
      },
    });
    try {
      const response = await axios.post(url, data, {
        headers: {
          pinata_api_key: key!,
          pinata_secret_api_key: secret!,
          "Content-Type": "application/json",
        },
      });
      return {
        success: true,
        pinataUrl:
          "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash,
      };
    } catch (err: any) {
      console.error("Pinata error", err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  public static async pinFileToIPFS(src: string, name: string) {
    const formData = new FormData();
    formData.append("file", src);

    const metadata = JSON.stringify({
      name: name + " trophy image",
    });
    formData.append("pinataMetadata", metadata);

    const options = JSON.stringify({
      cidVersion: 1,
    });
    formData.append("pinataOptions", options);

    try {
      const res = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": `multipart/form-data;`,
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return {
        success: true,
        imageUrl: "ipfs://" + res.data.IpfsHash,
      };
    } catch (err: any) {
      console.error("Pinata error", err);
      return {
        success: false,
        message: err.message,
      };
    }
  }
}
