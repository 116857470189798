import React from 'react';
import json from './data.json';
import { Player } from '@lottiefiles/react-lottie-player';
import Lottie from 'react-lottie-player';

export default function WindowIcon({
  isWindowHovered = false,
  setIsWindowHovered,
}: {
  isWindowHovered: boolean;
  setIsWindowHovered: (isWindowHovered: boolean) => void;
}) {
  return (
    <div>
      <Lottie
        style={{ background: '#A950FF', borderRadius: '15px', margin: 0 }}
        animationData={json}
        loop={true}
        play={isWindowHovered}
        goTo={50}
        className="lottie"
        // onLoopComplete={(e) => {
        //   setIsWindowHovered(false);
        // }}
      />
    </div>
  );
}
