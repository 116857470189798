import BaseApi from "./BaseApi";
import { INftApi } from "shared/src/api-interfaces/INftApi";
import qs from "qs";

export default class NftApi extends BaseApi {
  public static async getOwnedNfts(
    params: INftApi.List.Params
  ): Promise<INftApi.List.Response> {
    return this.makeRequest(`/nfts/${qs.stringify(params)}`, "GET");
  }

  public static async getAllNfts(
    params: INftApi.List.Params
  ): Promise<INftApi.List.Response> {
    return this.makeRequest(`/nfts/`, "GET");
  }
}
