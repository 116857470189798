import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import TextField from '../../common/TextInput';
import * as Yup from 'yup';
import ContactApi from '../../../api/ContactApi';
import { sendError, sendSuccess } from '../../../lib/helpers/toast';

interface IContactForm {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    subject: string;
    message: string;
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().nullable(),
    subject: Yup.string().required(),
    message: Yup.string().required()
});

export default function ContactForm() {
    return (
        <div>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: undefined,
                    subject: '',
                    message: ''
                }}
                onSubmit={async (values, actions) => {
                    try {
                        await ContactApi.sendMessage(values);
                        sendSuccess('Message sent!');
                        actions.resetForm();
                    } catch(err) {
                        sendError('Something went wrong while sending your message. Please email us directly at contact@trofied.com.')
                    }
                    actions.setSubmitting(false);
                }}
                validateOnBlur={true}
                validationSchema={validationSchema}
            >
            {(props: FormikProps<IContactForm>) => {
                const {
                    values, 
                    errors, 
                    touched, 
                    handleChange, 
                    handleBlur,
                    setFieldValue
                } = props;

                return (
                    <Form
                        className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
                    >
                        <div>
                            <TextField 
                                label="First Name"
                                name="firstName"
                                useDefaultStyles={false}
                                labelClass='mb-1 block text-sm font-medium text-warm-gray-900'
                                childClass='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-warm-gray-300 rounded-md'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!(errors.firstName && touched.firstName)}
                                errorMessage={errors.firstName}
                                value={values.firstName}
                            />
                        </div>
                        <div>
                            <TextField 
                                label="Last Name"
                                name="lastName"
                                useDefaultStyles={false}
                                labelClass='mb-1 block text-sm font-medium text-warm-gray-900'
                                childClass='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-warm-gray-300 rounded-md'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!(errors.lastName && touched.lastName)}
                                errorMessage={errors.lastName}
                                value={values.lastName}
                            />
                        </div>

                        <div>
                            <TextField 
                                label="Email"
                                name="email"
                                type='email'
                                useDefaultStyles={false}
                                labelClass='mb-1 block text-sm font-medium text-warm-gray-900'
                                childClass='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-warm-gray-300 rounded-md'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!(errors.email && touched.email)}
                                errorMessage={errors.email}
                                value={values.email}
                            />
                        </div>

                        <div>
                            <TextField 
                                label="Phone"
                                name="phoneNumber"
                                useDefaultStyles={false}
                                labelClass='mb-1 block text-sm font-medium text-warm-gray-900'
                                childClass='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-warm-gray-300 rounded-md'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!(errors.phoneNumber && touched.phoneNumber)}
                                errorMessage={errors.phoneNumber}
                                value={values.phoneNumber}
                            />
                        </div>
                        <div className="sm:col-span-2">
                            <TextField 
                                label="Subject"
                                name="subject"
                                useDefaultStyles={false}
                                labelClass='mb-1 block text-sm font-medium text-warm-gray-900'
                                childClass='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-warm-gray-300 rounded-md'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!(errors.subject && touched.subject)}
                                errorMessage={errors.subject}
                                value={values.subject}
                            />
                        </div>
                        <div className="sm:col-span-2">
                            <TextField 
                                label="Message"
                                name="message"
                                useDefaultStyles={false}
                                isTextArea={true}
                                labelClass='mb-1 block text-sm font-medium text-warm-gray-900'
                                childClass='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border border-warm-gray-300 rounded-md'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!(errors.message && touched.message)}
                                errorMessage={errors.message}
                                value={values.message}
                            />
                        </div>
                        <div className="sm:col-span-2 sm:flex sm:justify-end">
                            <button
                                type="submit"
                                className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                )
            }}
            </Formik>
        </div> 
    )
}