import React, { ReactChild } from 'react';

interface Props {
    icon?: JSX.Element;
    children: string | ReactChild;
    disabled?: boolean;
    size?: 'none' | 'medium' | 'large',
    type?: 'submit' | 'button';
    className?: string;
    onClick?: () => void;
}

const sizeStyles = {
    none: "",
    medium: "w-[127px] h-[43px] py-2 px-4 m-2",
    large: "w-[150px] h-[55px] py-2 px-4"
}

export default function Button(props: Props) {
    const { 
        icon,
        children, 
        disabled = false,
        size = 'none',
        type = 'button',
        className = '',
        onClick
    } = props;

    return (
        <button 
            disabled={disabled}
            className={`${className} ${sizeStyles[size]} transition duration-300`} 
            onClick={onClick}
            type={type}
        >
            {
                icon 
                ?
                <span className='flex justify-center'>
                    {icon}
                    {children}
                </span>
                :
                children
            }
        </button>
    )
}