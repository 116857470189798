import React from 'react';
import { Outlet } from "react-router-dom";
import AdminNavBar from './components/AdminNavBar';

export default function AdminPanel() {
    return (
        <div className="h-full">
            <AdminNavBar />
            <Outlet />
        </div>
    )
}