import React from 'react';

interface Props {
  className?: string;
  width?: number;
}

export default function Shelf(props: Props) {
  const { width = 823, className = 'w-full' } = props;

  return (
    <svg
      className={className}
      width="823"
      height="24"
      viewBox="0 0 823 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.0602 23.876L0 1.21421L823 0.875977L749.956 23.876H99.0602Z"
        fill="url(#paint0_linear_177_16727)"
        fillOpacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_177_16727"
          x1="411.5"
          y1="1.21421"
          x2="411.5"
          y2="19.8172"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
