import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import DealApi from '../../../api/DealApi';
import Formatters from '../../../lib/helpers/formatters';
import QrCodeIcon from '../../../assets/icons/QrCodeIcon';

interface Props {
  dealId: number;
}

export default function DealPreview({ dealId }: Props) {
  const { isLoading, data } = useQuery(`deal:${dealId}`, () =>
    DealApi.getDeal(dealId)
  );

  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <p></p>
      ) : data && data.data ? (
        <div className="flex flex-wrap justify-center items-center gap-6 md:gap-12 xl:gap-16">
          <div className="w-full md:w-auto text-center md:text-left">
            <button
              onClick={() => navigate(`/case/deal/${dealId}`)}
              className="group inline-block relative w-72 max-w-full overflow-hidden p-5 select-none bg-white border border-transparent hover:border-primary rounded-4xl shadow-dark-lg hover:shadow transition-all duration-300"
            >
              <img
                src={'https://trofied.s3.amazonaws.com/' + data.data.image}
                alt="Trofy"
                className="aspect-square p-12 object-center object-contain bg-white border border-light-300 rounded-xl"
              />

              <QrCodeIcon className="absolute right-[30px] bottom-[30px] w-5 h-5 text-light-550" />
            </button>
          </div>

          <div className="flex-1 md:max-w-[445px]">
            <ul className="divide-y divide-light-300">
              <li className="py-2 flex justify-between gap-5">
                <span className="text-sm text-secondary">Company</span>
                <p className="text-sm font-semibold text-right">
                  {data.data.companyName}
                </p>
              </li>

              <li className="py-2 flex justify-between gap-5">
                <span className="text-sm text-secondary">Seller</span>
                <p className="text-sm font-semibold text-right">
                  {data.data.sellers && data.data.sellers.length
                    ? data.data.sellers[0].name
                    : ''}
                </p>
              </li>

              <li className="py-2 flex justify-between gap-5">
                <span className="text-sm text-secondary">Buyer</span>
                <p className="text-sm font-semibold text-right">
                  {data.data.buyers && data.data.buyers.length
                    ? data.data.buyers[0].name
                    : ''}
                </p>
              </li>

              <li className="py-2 flex justify-between gap-5">
                <span className="text-sm text-secondary">Amount</span>
                <p className="text-sm font-semibold text-right">
                  {Formatters.formatBillions(data.data.amount)}
                </p>
              </li>

              <li className="py-2 flex justify-between gap-5">
                <span className="text-sm text-secondary">Transaction</span>
                <p className="text-sm font-semibold text-right">
                  {data.data.transactionType}
                </p>
              </li>

              <li className="py-2 flex justify-between gap-5">
                <span className="text-sm text-secondary">Closed</span>
                <p className="text-sm font-semibold text-right">
                  {Formatters.formatDate(data.data.closeDate)}
                </p>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
