import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileApi from '../../api/FileApi';
import ErrorMessage from './ErrorMessage';

interface FileDropProps {
  required?: boolean;
  label?: string;
  uploadPrefix: string;
  uploadName: string;
  onUpload: (uploadPath: string) => void;
}

export default function FileDrop(props: FileDropProps) {
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [uploadError, setUploadError] = useState<string | undefined>(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { required = false, label, uploadPrefix, uploadName, onUpload } = props;

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      setIsUploading(true);

      const unixTimestamp = new Date().getTime();
      const getUploadURLResponse = await FileApi.getPresignedUploadURL(
        `${uploadPrefix}/${uploadName}_${unixTimestamp}`
      );
      if (!getUploadURLResponse.data) {
        setUploadError('Something went wrong. Please try again.');
        setIsUploading(false);
        return;
      }

      const { fields } = getUploadURLResponse.data;

      const body = new FormData();
      for (const key of Object.keys(fields)) {
        body.append(key, fields[key]);
      }
      body.append('file', acceptedFiles[0]);

      console.log('body', body);

      const response = await fetch(getUploadURLResponse.data.url, {
        method: 'POST',
        body,
        mode: 'no-cors',
      });

      console.log(response);
      onUpload(getUploadURLResponse.data.uploadPath);
      setFileName(acceptedFiles[0].name);
      setUploadError(undefined);
      setIsUploading(false);
    } catch (err) {
      console.log(err);
      setUploadError('Failed to upload file.');
      setIsUploading(false);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="flex-column text-gray-700">
      <ErrorMessage error={uploadError} />
      {label && (
        <label className="block mb-1" htmlFor="field">
          {label}
          {required && <span className="text-red-600 ml-1">*</span>}
        </label>
      )}

      {isUploading ? (
        <div
          className={`flex mb-4 p-5 border border-dashed border-slate-400 rounded-md bg-slate-100`}
        >
          <span className="flex">Uploading...</span>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`group flex mb-4 p-5 border border-dashed border-slate-400 rounded-md ${
            isDragActive ? 'bg-slate-200' : 'bg-slate-100'
          }`}
        >
          <input {...getInputProps()} />
          <span className="flex items-center">
            <span className="flex-shrink-0 -my-2 -ml-2 w-9 h-9 mr-2 inline-flex items-center justify-center bg-transparent group-hover:bg-primary group-hover:text-white rounded-full transition-colors duration-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                />
              </svg>
            </span>

            Upload {label || 'File'}
            {fileName && (
              <span className="ml-6 font-light text-gray-500">{fileName}</span>
            )}
          </span>
        </div>
      )}
    </div>
  );
}
