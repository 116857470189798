import React, { useState, useEffect, createContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthApi from "../api/AuthApi";
import { IAuthApi } from 'shared/src/api-interfaces/IAuthApi';

interface AuthContextType {
    user: User|null;
    loadingUser: boolean;
    roles: string[]|null;
    signup: (request: IAuthApi.Signup.Request) => Promise<void>;
    login: (email: string, password: string, redirectPath: string) => Promise<void>;
    logout: () => Promise<void>;
}

interface User {
    profileImage: string;
}

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    let [loadingUser, setLoadingUser] = useState<boolean>(true);
    let [user, setUser] = useState<User|null>(null);
    let [roles, setRoles] = useState<string[]|null>(null);
    let location = useLocation();

    const navigate = useNavigate();

    const verifyToken = useCallback(async (redirectPath: string) => {
        setLoadingUser(true);

        try {
            const response: IAuthApi.VerifyToken.Response = await AuthApi.verifyToken();
            if (response.data) {
                const {
                    profileImage,
                    roles
                } = response.data;

                setUser({
                    profileImage
                });
                setRoles(roles);
                setLoadingUser(false);
                navigate(redirectPath, { replace: true });
                return;
            }
        } catch(err) {
            console.log('Failed to verify token')
        }

        setUser(null);
        setRoles(null);
        setLoadingUser(false);
    }, [navigate])

    const signup = async (request: IAuthApi.Signup.Request) => {
        
        const response: IAuthApi.Signup.Response = await AuthApi.signup(request);
        if (response.data) {
            const {
                token,
                profileImage, 
                roles
            } = response.data;

            setUser({
                profileImage
            });
            setRoles(roles);

            localStorage.setItem('loadTimeout', 'true');
            localStorage.setItem('trofiedToken', token);

            navigate('/case', { replace: true });
        }
    };

    const login = async (email: string, password: string, redirectPath: string) => {
        const response = await AuthApi.login({email, password});
        if (response.data) {
            const {
                token,
                profileImage,
                roles
            } = response.data;

            setUser({
                profileImage
            });
            setRoles(roles);

            localStorage.setItem('loadTimeout', 'true');
            localStorage.setItem('trofiedToken', token);

            navigate(redirectPath, { replace: true });
        }
    };

    const logout = async () => {
        try {
            await AuthApi.logout();
        } catch(err) {
            console.log(err);
        }

        setUser(null);
        setRoles(null);

        localStorage.removeItem('loadTimeout');
        localStorage.removeItem('trofiedToken');
        
        navigate('/', { replace: true });
    };

    useEffect(() => {
        let redirectPath = location.pathname;
        if (redirectPath === '/login') {
            redirectPath = '/case'
        }
        verifyToken(redirectPath);
    },[verifyToken, location.pathname, location.search]);

    let value = { user, loadingUser, roles, signup, login, logout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;