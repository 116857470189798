import BaseApi from './BaseApi';
import { IUserProfileApi } from 'shared/src/api-interfaces/IUserProfileApi';

export default class UserProfileApi extends BaseApi {
	// TODO: Refactor to use unique id
	// public static async getProfile(
	//     username: string
	// ): Promise<IUserProfileApi.Read.Response> {
	//     return BaseApi.makeRequest(`/profiles/${username}`, 'GET');
	// }

	public static async getMyProfile(): Promise<IUserProfileApi.GetMyProfile.Response> {
		return BaseApi.makeRequest(`/profiles/me`, 'GET');
	}

	public static async updateMyProfile(
		body: IUserProfileApi.UpdateMyProfile.Request
	): Promise<IUserProfileApi.UpdateMyProfile.Response> {
		return BaseApi.makeRequest(`/profiles/me`, 'PATCH', body);
	}

	public static async getSchools(
		query: string
	): Promise<IUserProfileApi.GetSchools.Response> {
		return BaseApi.makeRequest(`/profiles/schools?query=${query}`, 'GET');
	}

	public static async getCompanies(
		query: string
	): Promise<IUserProfileApi.GetCompanies.Response> {
		return BaseApi.makeRequest(`/profiles/companies?query=${query}`, 'GET');
	}
}
