import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import CompaniesApi from '../../../api/CompaniesApi';
import LocationBlockIcon from '../../../assets/icons/LocationBlockIcon';
import PeopleBlockIcon from '../../../assets/icons/PeopleBlockIcon';
import TrofiesBlockIcon from '../../../assets/icons/TrofiesBlockIcon';
import Loading from '../../common/Loading';
import CompanyNav from './CompanyNav';

export default function CompanyProfile() {
    const style: any = { '-webkit-text-stroke': '1px white', '-webkit-text-fill-color': 'transparent' }

    const params = useParams();
    const {
        isLoading,
        data,
    } = useQuery(`company:${params.companyId}`, () => CompaniesApi.getCompany(parseInt(params.companyId!)));

    return (
        <div>
             {
                isLoading
                ?
                <Loading />
                :
                data && data.data
                ?
                <div>
                    <div className='bg-company-image relative bg-background-company w-full h-[350px] top'>
                        <h1 className='lg:text-[80px] lg:leading-[100px] text-[50px] leading-[70px] absolute md:top-[25%] top-[20%] right-0 font-poppins mb-1 font-[900] uppercase' style={style}>{data.data.name}</h1>
                    </div>
                    <div className='lg:w-2/3 md:w-[80%] w-[90%] lg:pl-[65px] pl-0 mx-auto'>
                        <div className='flex items-center static'>
                            <div className='lg:w-[324px] w-[200px] h-[200px] lg:h-[324px] flex absolute lg:top-[12rem] top-[18rem] row p-[20px] bg-white rounded-[20px] drop-shadow-xl'>
                                <div className="bg-black relative w-full h-full block rounded-[10px] my-auto">
                                    <img
                                        src={'https://trofied.s3.amazonaws.com/' + data.data.logo}
                                        alt='Company Logo'
                                        className="w-full h-full z-10 absolute align-middle"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='lg:w-2/3 md:w-[80%] md:gap-[60px] gap-[20px] w-[90%] lg:pl-[65px] pl-0 mx-auto md:mt-[5.5rem] mt-[5rem] mb-10 flex lg:justify-center justify-content-end'>
                        <div className='flex sm:flex-row flex-col sm:items-start items-center gap-[12px]'>
                            <LocationBlockIcon />
                            <div className='ml-2'>
                                <p className='font-bold sm:text-xl text-sm leading-4'>{data.data.city}</p>
                                <p className='font-bold sm:text-xl text-sm leading-4'>{data.data.state}</p>
                                <p className='text-gray-400 text-xs font-light'>Location</p>
                            </div>
                        </div>
                        <div className='flex sm:flex-row flex-col sm:items-start items-center gap-[12px]'>
                            <PeopleBlockIcon />
                            <div className='ml-2'>
                                <p className='font-bold sm:text-xl text-md'>5,452</p>
                                <p className='text-gray-400 text-xs font-light'>People</p>
                            </div>
                        </div>
                        <div className='flex sm:flex-row flex-col sm:items-start items-center gap-[12px]'>
                            <TrofiesBlockIcon />
                            <div className='ml-2'>
                                <p className='font-bold sm:text-xl text-md'>44</p>
                                <p className='text-gray-400 text-xs font-light'>Trofies</p>
                            </div>
                        </div>
                    </section>
                    <section className='lg:w-2/3 md:w-[80%] w-[90%] lg:pl-[65px] pl-0 mx-auto mt-[50px] mb-12'>
                        <h1 className='text-3xl font-poppins mb-1 font-bold uppercase'>{data.data.name}</h1>
                        <p className='text-md font-poppins mb-2'>Private Equity Company</p>
                        <p className='text-sm text-neutral-400 font-light'>
                            Sed ullamcorper magna nisl leo pellentesque. Et auctor erat ut nisl lacus. Aliquam tellus pretium, natoque nunc nunc sed quis aliquet purus. Elit nulLorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl velit morbi ultrices sed. Erat consequat facilisis libero pellentesque malesuada nunc, egestas. At enim mi amet, quis. Mattis netus lacus augue malesuada tincidunt mauris ultrices vel. Ultricies et, in nec eget aliquet aliquam massa pellentesque. Luctus sed fringilla scelerisque malesuada neque facilisis. Est
                        </p>
                    </section>
                    <section className='lg:w-2/3 md:w-[80%] w-[90%] lg:pl-[65px] pl-0 mx-auto mb-20 bg-white rounded-md h-40 drop-shadow-wide'>
                        <CompanyNav />
                    </section>
                </div>
                :
                <></>
            }
        </div>
    )
}