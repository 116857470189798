import qs from 'qs';
import BaseApi from "./BaseApi";
import { ICompaniesApi } from 'shared/src/api-interfaces/ICompaniesApi';

export default class CompaniesApi extends BaseApi {

    public static async createCompany(
        body: ICompaniesApi.Create.Request
    ): Promise<ICompaniesApi.Create.Response> {
        return BaseApi.makeRequest(`/companies`, 'POST', body);
    }

    public static async deleteCompany(
        companyId: number
    ): Promise<ICompaniesApi.Delete.Response> {
        return BaseApi.makeRequest(`/companies/${companyId}`, 'DELETE');
    }

    public static async getCompany(
        companyId: number
    ): Promise<ICompaniesApi.Read.Response> {
        return BaseApi.makeRequest(`/companies/${companyId}`, 'GET');
    }

    public static async listCompanies(params: ICompaniesApi.List.Params): Promise<ICompaniesApi.List.Response> {
        return this.makeRequest(`/companies?${qs.stringify(params)}`, 'GET');
    }

    public static async updateCompany(
        companyId: number,
        body: ICompaniesApi.Update.Request
    ): Promise<ICompaniesApi.Update.Response> {
        return BaseApi.makeRequest(`/companies/${companyId}`, 'PATCH', body);
    }
}