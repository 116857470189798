import React, { useState } from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import TextField from "../../../components/common/TextInput";
import SelectInput from "../../../components/common/SelectInput";
import states from "../../../lib/constants/States.constant";
import ErrorMessage from "../../../components/common/ErrorMessage";
import UserProfileApi from "../../../api/UserProfileApi";
import Modal from "../../../components/common/Modal";
import ICompany from "shared/src/model-interfaces/ICompany";
import FileDrop from "../../../components/common/FileDrop";
import CompaniesApi from "../../../api/CompaniesApi";
import { ICompaniesApi } from "shared/src/api-interfaces/ICompaniesApi";
import SlideOver from "../../../components/common/SlideOver";
import Loading from "../../../components/common/Loading";
import NftInfo from "shared/src/model-interfaces/INftInfo";

interface Props {
  open: boolean;
  trofy: NftInfo;
  setOpen: (open: boolean) => void;
}

export default function TrofyDetailsForm({ trofy, open, setOpen }: Props) {
  return (
    <SlideOver open={open} setOpen={setOpen} title="Trofy Details">
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Trofy {trofy.id}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            <div className="aspect-w-3 aspect-h-2">
              <img
                className="rounded-lg object-cover shadow-lg h-40 w-40"
                src={trofy.image}
                alt=""
              />
            </div>
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Trofy name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {trofy.name}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Total count</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {trofy.totalCount}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Unclaimed</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {trofy.count}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {trofy.description}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </SlideOver>
  );
}
