import React from 'react';
import { useQuery } from 'react-query';
import UsersApi from '../../../api/UsersApi';
import Loading from '../../../components/common/Loading';
import Table from '../../../components/common/Table';
import Formatters from '../../../lib/helpers/formatters';

const columns = [
    {
        id: 'users-table',
        Header: '',
        columns: [
            {
                Header: 'Id',
                accessor: 'id'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'User Since',
                accessor: 'createdAt',
                Cell: ({ value }: any) => Formatters.formatDate(value)
            }
        ]
    }
]

export default function Users() {
    const {
        isLoading,
        data,
    } = useQuery('list-users', () => UsersApi.listUsers({}));

    if (isLoading) {
        return <Loading />
    }

    if (!data || !data.data) {
        return <p>Failed to load Users</p>
    }

    return (
        <div className='flex flex-column justify-center'>
            <div className='w-4/5 p-4'>
                <Table columns={columns} data={data.data} />
            </div>
        </div>
    )
}