import Pinata from "../pinata";
import { ethers } from "ethers";
import { ChainId } from "../ChainId.Constants";

import contractABI from "../contract-abi.json";

const contractAddress: string = process.env.REACT_APP_CONTRACT_ADDRESS!;

interface metadata {
  name?: string;
  image?: string;
  description?: string;
  count?: string;
}

interface IMintNftBody {
  name?: string;
  description?: string;
  image?: string;
  count?: string;
}

export default async function MintTrofy(
  body: IMintNftBody
): Promise<{ success: boolean; message: string }> {
  const { name, description, image, count } = body;
  const chainId = await (window as any).ethereum.request({
    method: "eth_chainId",
  });
  if (chainId != ChainId.goerli) {
    return {
      success: false,
      message: "Please switch to Goerli test net",
    };
  }

  //upload image to pinata
  const imageResult = await Pinata.pinFileToIPFS(image!, name!);
  if (!imageResult.success) {
    console.error("Pinata error", imageResult.message);
    return {
      success: false,
      message: "Pinata error: " + imageResult.message,
    };
  }

  //make metadata
  const metadata: metadata = {
    name,
    image: imageResult.imageUrl,
    description,
    count,
  };

  //make pinata call
  const pinataResponse = await Pinata.pinJSONToIPFS(metadata);
  if (!pinataResponse.success) {
    console.error("Pinata error", pinataResponse.message);
    return {
      success: false,
      message: "Pinata error: " + pinataResponse.message,
    };
  }

  const tokenURI = pinataResponse.pinataUrl;
  // @ts-ignore
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  await provider.send("eth_requestAccounts", []);

  const signer = provider.getSigner();

  const contract = new ethers.Contract(contractAddress, contractABI, signer);

  try {
    const nftTxn = await contract.mintTrophy(parseInt(count!), tokenURI);
    await nftTxn.wait();
    return {
      success: true,
      message: `Trofy Minted!`,
    };
  } catch (err: any) {
    console.error("Error minting", err);
    return {
      success: false,
      message: "Minting error: " + err.message,
    };
  }
}