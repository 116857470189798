import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Loading from '../components/common/Loading';

interface Props {
    roles?: string[];
}

export default function RequireAuth({ children, roles: allowedRoles }: { children: JSX.Element } & Props) {
    const {loadingUser, user, roles} = React.useContext(AuthContext);
    const location = useLocation();
    const [isDelaying, setIsDelaying] = useState<boolean>(loadingUser);

    useEffect(() => {
        if (isDelaying) {
            setTimeout(() => {
                setIsDelaying(false);
            }, 3000);
        }
    }, [isDelaying]);

    if (loadingUser || isDelaying) {
        return <Loading />;
    }

    const hasPermission = !allowedRoles 
        || (roles && allowedRoles.filter(value => roles.includes(value)).length);

    return (
        <>
            {
                user && hasPermission
                ?
                children
                :
                <Navigate to="/login" state={{ from: location }} replace />
            }
        </>
    )
}