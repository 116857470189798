import json from './data.json';
import Lottie from 'react-lottie-player';

export default function ArrowIcon({
  isArrowHovered = false,
  setIsArrowHovered,
}: {
  isArrowHovered: boolean;
  setIsArrowHovered: (isArrowHovered: boolean) => void;
}) {
  return (
    <div>
      <Lottie
        style={{ background: '#DDAA00', borderRadius: '15px', margin: 0 }}
        animationData={json}
        loop={true}
        play={isArrowHovered}
        className="lottie"
        // onLoopComplete={() => {
        //   setIsArrowHovered(false);
        // }}
      />
    </div>
  );
}
