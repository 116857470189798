import { PencilAltIcon, PlusCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import CompaniesApi from '../../../api/CompaniesApi';
import Button from '../../../components/common/Button';
import Loading from '../../../components/common/Loading';
import Table from '../../../components/common/Table';
import CreateCompanyForm from './CreateCompanyForm';
import EditCompanyForm from './EditCompanyForm';

export default function Companies() {
    const [createFormOpen, setCreateFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [currentCompanyId, setCurrentCompanyId] = useState(undefined);
        const columns = [
        {
            id: 'companies-table',
            Header: '',
            columns: [
                {
                    Header: 'Id',
                    accessor: 'id'
                },
                {
                    Header: 'Name',
                    accessor: 'name'
                },
                {
                    Header: 'Page Path',
                    accessor: 'slug'
                },
                {
                    Header: 'City',
                    accessor: 'city'
                },
                {
                    Header: 'State',
                    accessor: 'state'
                },
                {
                    Header: 'Employee Count',
                    accessor: 'employeeCount'
                },
                {
                    Header: '',
                    id: 'edit',
                    Cell: ({ row }: any) => {
                        console.log(row)
                        return (
                            <Button
                                children=''
                                icon={<PencilAltIcon className='h-5 w-5 my-auto mr-2'/>}
                                onClick={() => {
                                    setCurrentCompanyId(row.values.id);
                                    setEditFormOpen(true);
                                }}
                                className="bg-transparent hover:text-blue-500 text-gray-700"
                            />
                        )
                    }
                }
            ]
        }
    ]
    const {
        isLoading,
        data,
    } = useQuery('list-companies', () => CompaniesApi.listCompanies({}));

    useEffect(() => {
        
    }, [currentCompanyId])

    if (isLoading) {
        return <Loading />
    }

    if (!data || !data.data) {
        return <p>Failed to load Companies</p>
    }

    return (
        <div className='flex-column justify-center'>
            <div className='w-4/5 mt-4 flex mx-aut justify-end'>
                <Button
                    children='New'
                    size='medium'
                    icon={<PlusCircleIcon className='h-5 w-5 my-auto mr-2' />}
                    onClick={() => setCreateFormOpen(true)}
                />
            </div>
            <div className='w-4/5 mt-2 mx-auto'>
                <Table columns={columns} data={data.data} />
            </div>
            {currentCompanyId && <EditCompanyForm 
                open={editFormOpen} 
                setOpen={setEditFormOpen} 
                companyId={currentCompanyId}
            />}
            <CreateCompanyForm open={createFormOpen} setOpen={setCreateFormOpen} />
        </div>
    )
}