export default class Formatters {

    public static formatDate(date: string|Date): string {
        let newDate: Date;
        if (typeof date === 'string') {
            newDate = new Date(date);
        } else {
            newDate = date;
        }

        return newDate.toLocaleDateString("en-US", {
            year: 'numeric', 
            month: 'long', 
            day: 'numeric'
        });
    }

    public static formatBillions(dollarAmount: number): string {
        return '$' + (dollarAmount/1000000000).toFixed(1).toLocaleString() + 'B';
    }


}