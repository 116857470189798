import React, { useState } from 'react';
import { useQuery } from 'react-query';
import AdminTrofies from './AdminTrofies';
import MintTrofyModal from './MintTrofyModal';


export default function Trofies() {
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const initialValues = {name: undefined, description: undefined, image: undefined, count: undefined} 

    return (
        <div className="h-max">
            <AdminTrofies onEdit={() => setEditModalOpen(true)} />
            <MintTrofyModal 
                open={editModalOpen} 
                setOpen={setEditModalOpen} 
                initialValues={initialValues} 
            /> 
        </div>
    )

}