import React from 'react';

export default function SearchIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6437 20.1444L17.4959 15.9966C18.753 14.3511 19.4424 12.3551 19.4427 10.2488C19.4427 7.71063 18.4541 5.32417 16.659 3.52941C14.8643 1.73465 12.4781 0.746094 9.93962 0.746094C7.40141 0.746094 5.01495 1.73465 3.22019 3.52941C-0.484856 7.23477 -0.484856 13.2635 3.22019 16.9683C5.01495 18.7633 7.40141 19.7519 9.93962 19.7519C12.0458 19.7516 14.0418 19.0622 15.6874 17.8051L19.8352 21.9529C20.0846 22.2027 20.4122 22.3276 20.7394 22.3276C21.0666 22.3276 21.3942 22.2027 21.6437 21.9529C22.1432 21.4536 22.1432 20.6436 21.6437 20.1444ZM5.02869 15.1598C2.32094 12.452 2.32125 8.04597 5.02869 5.33791C6.34042 4.0265 8.08459 3.30397 9.93962 3.30397C11.795 3.30397 13.5388 4.0265 14.8505 5.33791C16.1623 6.64963 16.8848 8.39381 16.8848 10.2488C16.8848 12.1042 16.1623 13.848 14.8505 15.1598C13.5388 16.4715 11.795 17.194 9.93962 17.194C8.08459 17.194 6.34042 16.4715 5.02869 15.1598Z"
        fill="currentColor"
      />
    </svg>
  );
}
