export default function ComingSoon() {
  return (
    <section className="min-h-[470px] md:min-h-[560px] lg:md:min-h-[580px] xl:min-h-0 relative pt-16 md:pt-20 xl:pb-20 overflow-hidden bg-neutral-50">
      <div className="container">
        <div className="flex flex-wrap items-center">
          <h1 className="h1 font-bold w-full xl:w-auto">Page Coming Soon!</h1>

          <svg
            className="flex-shrink-0 absolute bottom-0 right-0 xl:static ml-auto max-w-[410px] h-auto w-[70%] md:w-1/2 lg:w-full"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-neutral-300"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}
