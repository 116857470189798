import React from 'react';

    interface Props {
        onClick: () => void
    }

    export default function ArrowLeftIcon({onClick}: Props) {
    return (
        <div onClick={onClick} style={{background: 'linear-gradient(90deg, rgba(252, 252, 252, 0.9) 40.29%, rgba(252, 252, 252, 0) 108.76%)'}} className="cursor-pointer absolute z-[100] w-[140px] h-[385px]">
            <svg className='absolute top-[70%] left-[25%]' width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.94052 14.1768L14.8164 0.33925C15.271 -0.113854 16.0071 -0.113092 16.461 0.341594C16.9145 0.796221 16.9133 1.53268 16.4586 1.98614L3.40866 15.0001L16.4591 28.0139C16.9137 28.4674 16.9149 29.2034 16.4614 29.6581C16.2339 29.886 15.9358 30 15.6378 30C15.3405 30 15.0436 29.8868 14.8165 29.6604L0.94052 15.8233C0.721556 15.6054 0.598684 15.309 0.598684 15.0001C0.598684 14.6912 0.721909 14.395 0.94052 14.1768Z" fill="black"/>
            </svg>
        </div>
    )
}