import React from "react";

interface Props {
  name: string;
  value: string | number | undefined;
  options: Option[];
  error?: boolean;
  success?: boolean;
  required?: boolean;
  label?: string;
  errorMessage?: string;
  successMessage?: string;
  infoMessage?: string;
  parentClass?: string;
  childClass?: string;
  labelClass?: string;
  style?: "small";
  onChange: (e: any) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
}

interface Option {
  label: string;
  value: string | number;
}

export default function SelectInput(props: Props) {
  const {
    name,
    options,
    error = false,
    success = false,
    required = false,
    value,
    label,
    errorMessage = "",
    successMessage = "",
    parentClass = "",
    childClass = "",
    labelClass = "",
    infoMessage,
    style = "default",
    onChange,
    onBlur,
  } = props;

  const styles = {
    wrapper: {
      default: "my-auto text-gray-700",
      small: "my-auto text-gray-700",
    },
    select: {
      default: `w-full h-10 text-base placeholder-gray-600 border ${
        error
          ? "border-red-700"
          : success
          ? "border-green-700"
          : "border-slate-400"
      } rounded-lg focus:shadow-outline`,
      small: `w-24 h-8 py-1 text-sm placeholder-gray-600 border border-slate-400 rounded-lg focus:shadow-outline`,
    },
  };
  return (
    <div className={styles.wrapper[style] + parentClass}>
      {label && (
        <label className={"block mb-1" + labelClass} htmlFor="field">
          {label}
          {required && <span className="text-red-600 ml-1">*</span>}
        </label>
      )}
      <select
        className={styles.select[style] + childClass}
        id="field"
        name={name}
        aria-describedby="fieldMessage"
        value={value}
        defaultValue={undefined}
        onChange={onChange}
        onBlur={onBlur}
      >
        <option hidden disabled value={undefined}></option>
        {options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      {error ? (
        <span className="text-xs text-red-700" id="fieldMessage">
          {errorMessage}
        </span>
      ) : success ? (
        <span className="text-xs text-green-700" id="fieldMessage">
          {successMessage}
        </span>
      ) : infoMessage ? (
        <span className="text-xs text-gray-600" id="fieldMessage">
          {infoMessage}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}
