import { Method } from 'axios';
import { encode } from "base-64";

export default class BaseApi {
    private static baseUrl: string = process.env.REACT_APP_API_URL!;

    public static async basicAuth(password?: string): Promise<void> {
        let config: RequestInit = {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
        };

        config.headers = new Headers();
        config.headers.set('Content-Type', 'application/json');
        config.headers.set('Access-Control-Allow-Origin', '*');
        if (password) {
            config.headers.set('Authorization', `Basic ${encode("admin:" + password)}`);
        }
        
        const url = this.baseUrl;
        const response = await fetch(url, config);
        console.log(response);
                
        if (!response.ok) {
            throw new Error('Invalid password.');
        }
    }

    public static async makeRequest<RequestType, ResponseType>(path: string, method: Method, body?: RequestType): Promise<ResponseType> {
        console.log('Body', JSON.stringify(body))

        let config: RequestInit = {
            method,
            mode: 'cors',
            credentials: 'include'
        };

        config.headers = new Headers();

        if (body) {
            const bodyString = JSON.stringify(body);
            config.body = bodyString;
            config.headers.set('Content-Length', bodyString.length.toString());
        }

        config.headers.set('Content-Type', 'application/json');
        config.headers.set('Access-Control-Allow-Origin', '*');

        const token = localStorage.getItem('trofiedToken');
        if (token) {
            config.headers.set('Authorization', `Bearer ${token}`);
        }

        const url = this.baseUrl + path;
        const response = await fetch(url, config);
        const responseData = await response.json();
                
        console.log('Response Data', responseData);

        if (!response.ok) {
            throw new Error(responseData.message);
        }
        
        return responseData;
    }

    private static _getAuthHeader(): { [key: string]: string } {
        const token = localStorage.getItem('trofiedToken');
        if (token) {
            return {
                authorization: `Bearer ${token}`
            }
        }

        return {};
    }
}