import { useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import SettingsApi from '../../../api/SettingsApi';
import UserProfileApi from '../../../api/UserProfileApi';
import CoinbaseProfile from '../Settings/CoinbaseProfile';
import ConnectCoinbase from '../Settings/ConnectCoinbase';
import GetConnectedWallet from '../../../utils/WalletFunctions/GetConnectedWallet';
import ErrorMessage from '../../common/ErrorMessage';

import metamaskFoxImage from '../../../assets/images/brand/metamask-fox.svg';
import coinbaseImage from '../../../assets/images/brand/coinbase.png';

export default function Wallet() {
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const editProfile = useMutation(UserProfileApi.updateMyProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries('my-profile');
    },
    onError: (err) => {
      console.log(err);
      setSubmitError('Something went wrong, please try again.');
    },
  });

  const { data } = useQuery('mySettings', SettingsApi.getMySettings);

  const { data: dataOfUserProfile } = useQuery(
    'my-profile',
    UserProfileApi.getMyProfile
  );

  const connectWallet = async () => {
    const { success, address, status } = await GetConnectedWallet();
    if (success) {
      const values = {
        ...dataOfUserProfile?.data?.profile,
        walletAddress: address,
      };
      editProfile.mutate(values);
    }
  };

  return (
    <>
      <section className="relative py-16 lg:py-24 overflow-hidden bg-neutral-50">
        <div className="container">
          <div className="lg:relative">
            <svg
              className="flex-shrink-0 absolute bottom-0 right-0 lg:bottom-auto ml-auto max-w-[270px] lg:max-w-[410px] h-auto w-[70%] md:w-1/2 lg:w-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-neutral-300"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>

          <div className="relative">
            <div className="max-w-[700px] lg:pt-8 pb-12 md:pb-16">
              <h1 className="h1 font-bold">Connect Your Wallet</h1>

              <p className="mt-4 lg:mt-6 text-base md:text-xl text-secondary">
                Connect your crypto wallet to claim ownership of any of your
                Trofies minted as non-fungible tokens (NFTs). If you don't have
                a wallet yet, you can create one by selecting one of the
                providers below.
              </p>
            </div>

            <div className="flex justify-center">
              <div className="w-full max-w-[700px]">
                <ErrorMessage error={submitError} />

                {!dataOfUserProfile ||
                  (!dataOfUserProfile.data ? (
                    <p>Failed to load data</p>
                  ) : (
                    <ul className="flex flex-col w-full divide-y divide-light-300 bg-white border border-light-300 shadow-dark-lg rounded-4xl overflow-hidden">
                      <li>
                        <button
                          className="w-full flex text-left gap-5 p-6 hover:bg-secondary hover:bg-opacity-20 active:bg-opacity-40 transition-colors duration-300"
                          onClick={async () => await connectWallet()}
                        >
                          <img
                            src={metamaskFoxImage}
                            className="flex-shrink-0 w-9 h-9"
                            alt="Metamask Logo"
                          />

                          <div className="overflow-hidden self-center">
                            <h6 className="text-base font-semibold text-black">
                              MetaMask
                            </h6>
                            <div className="overflow-hidden text-ellipsis text-xs text-secondary">
                              {/* {dataOfUserProfile?.data?.profile.walletAddress} */}
                            </div>
                          </div>
                        </button>
                      </li>

                      <li>
                        <button 
                        className="relative w-full flex text-left gap-5 p-6 hover:bg-secondary hover:bg-opacity-20 active:bg-opacity-40 transition-colors duration-300"
                        onClick={async () => await connectWallet()}
                       >
                          <img
                            src={coinbaseImage}
                            className="flex-shrink-0 w-9 h-9"
                            alt="Coinbase Logo"
                          />

                          <div className="overflow-hidden self-center">
                            <h6 className="text-base font-semibold text-black">
                              Coinbase Wallet
                            </h6>
                            {/* <div className="overflow-hidden text-xs text-secondary">
                              {data?.data?.coinbase.isConnected ? (
                                <CoinbaseProfile />
                              ) : (
                                <>
                                  <ConnectCoinbase />
                                </>
                              )}
                            </div> */}
                          </div>
                        </button>
                      </li>
                    </ul>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
