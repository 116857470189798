import React from 'react';

export default function ExploreIcon({ className }: { className: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 19 20" fill="currentColor">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none">
                <g id="Group-613" fill="currentColor">
                    <path d="M9.49814,10.5451 C10.0776,10.5451 10.5432,10.0749 10.5432,9.50009 C10.5432,8.92533 10.0777,8.45508 9.49814,8.45508 C8.92338,8.45508 8.45312,8.92533 8.45312,9.50009 C8.45312,10.0749 8.92342,10.5451 9.49814,10.5451 Z" id="Shape"></path>
                    <path d="M9.5,0 C4.25127,0 0,4.25127 0,9.5 C0,14.744 4.25127,19 9.5,19 C14.7487,19 19,14.744 19,9.5 C19,4.25127 14.7488,0 9.5,0 Z M11.5805,11.5805 L3.79999,15.2 L7.4195,7.4195 L15.2,3.79999 L11.5805,11.5805 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
    )
}