import React from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Footer from '../common/Footer';
import NavBar from '../common/NavBar';
import Landing from './Landing';

export default function Root() {
    const location = useLocation();
    return (
        <div className="h-full">
            <NavBar />
            {
                location.pathname === '/'
                ?
                <Landing />
                :
                <Outlet />
            }
            <Footer />
        </div>
    )
}