import React from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import json from "./data.json";

export default function ShareIconAnimated() {
    return (
        <Player
            style={{width: '15px', height: '15px'}}
            src={json}
            loop
            autoplay
        />
    );
}