import React, {useState} from 'react';
import { useQuery } from 'react-query';
import {useParams} from 'react-router-dom';
import DealApi from '../../../api/DealApi';
import Formatters from '../../../lib/helpers/formatters';
import Loading from '../../common/Loading';
import PrefIcon from "../../../assets/icons/PrefIcon";
import BuysideSection from "./BuysideSection";
import CompanySection from "./CompanySection";

export default function DealProfile() {
    const params = useParams();

    const {
        isLoading,
        data,
    } = useQuery(`deal:${params.dealId}`, () => DealApi.getDeal(parseInt(params.dealId!)));

    const [isHovering, setIsHovering] = useState<any>(false);
    const [usersIndex, setUsersIndex] = useState<any>(0);

    function userActive (event:any) {
        setUsersIndex(event.id)
        setIsHovering(true)
    }

    return (
        <div className='bg-[#FCFCFC]'>
            {
                isLoading
                ?
                <Loading />
                :
                data && data.data
                ?
                <div>
                    <div className='flex bg-[#FCFCFC] static justify-center'>
                        <div className={`bg-diagonal-lines-pattern w-full h-[320px] top`}></div>
                        <PrefIcon />
                        <h1 className='absolute top-[10rem] text-white text-3xl font-bold'>{data.data.companyName}</h1>
                        <h3 className='absolute top-[13rem] text-white text-md font-light'>Associate Sixth  Street NOWHERE, FL</h3>
                        <div className='flex lg:flex-row flex-col absolute top-[14rem] row p4 m-6 md:w-1/2 w-[90%] bg-white rounded-2xl drop-shadow-xl'>
                            <div className='rounded-[24px] bg-white p-[17px] lg:w-1/3 w-2/3 h-full lg:mx-10 mx-auto m-10 text-center drop-shadow-right'>
                                <div
                                    className="w-full relative aspect-w-1 aspect-h-1 shadow rounded-2xl overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                                    <img
                                        src={'https://trofied.s3.amazonaws.com/' + data.data.image}
                                        alt='Deal Image'
                                        className="w-full h-full object-center object-cover group-hover:opacity-75 p-10"
                                    />

                                    <svg width="19" height="19" viewBox="0 0 19 19" className='absolute right-[20px] bottom-[20px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3934 5.96012C17.0277 5.96012 16.7311 5.66359 16.7311 5.29782V2.78515C16.7311 2.49352 16.4939 2.25624 16.2023 2.25624H13.6896C13.3239 2.25624 13.0273 1.9597 13.0273 1.59394C13.0273 1.22817 13.3239 0.931641 13.6896 0.931641H16.2023C17.2243 0.931641 18.0557 1.76313 18.0557 2.78515V5.29782C18.0557 5.66359 17.7592 5.96012 17.3934 5.96012Z" fill="#CDCDCD"/>
                                        <path d="M1.51386 5.96012C1.1481 5.96012 0.851562 5.66359 0.851562 5.29782V2.78515C0.851562 1.76313 1.68301 0.931641 2.70507 0.931641H5.21766C5.58342 0.931641 5.87995 1.22817 5.87995 1.59394C5.87995 1.9597 5.58342 2.25624 5.21766 2.25624H2.70507C2.41344 2.25624 2.17616 2.49352 2.17616 2.78515V5.29782C2.17616 5.66359 1.87967 5.96012 1.51386 5.96012Z" fill="#CDCDCD"/>
                                        <path d="M5.21766 18.1435H2.70507C1.68306 18.1435 0.851562 17.312 0.851562 16.29V13.7775C0.851562 13.4118 1.1481 13.1152 1.51386 13.1152C1.87963 13.1152 2.17616 13.4118 2.17616 13.7775V16.29C2.17616 16.5816 2.41339 16.8189 2.70507 16.8189H5.21766C5.58342 16.8189 5.87995 17.1154 5.87995 17.4812C5.87995 17.8469 5.58342 18.1435 5.21766 18.1435Z" fill="#CDCDCD"/>
                                        <path d="M16.2023 18.1435H13.6896C13.3239 18.1435 13.0273 17.8469 13.0273 17.4812C13.0273 17.1154 13.3239 16.8189 13.6896 16.8189H16.2023C16.4939 16.8189 16.7311 16.5816 16.7311 16.29V13.7775C16.7311 13.4118 17.0277 13.1152 17.3934 13.1152C17.7592 13.1152 18.0557 13.4118 18.0557 13.7775V16.29C18.0557 17.312 17.2242 18.1435 16.2023 18.1435Z" fill="#CDCDCD"/>
                                        <path d="M7.40317 8.53263H4.88754C4.30339 8.53263 3.82812 8.05745 3.82812 7.47335V4.95785C3.82812 4.3737 4.30339 3.89844 4.88754 3.89844H7.40317C7.98732 3.89844 8.46258 4.3737 8.46258 4.95785V7.47335C8.46263 8.05741 7.98736 8.53263 7.40317 8.53263ZM5.15277 7.20803H7.13803V5.22303H5.15277V7.20803Z" fill="#CDCDCD"/>
                                        <path d="M7.40317 15.1869H4.88754C4.30339 15.1869 3.82812 14.7117 3.82812 14.1275V11.6121C3.82812 11.028 4.30339 10.5527 4.88754 10.5527H7.40317C7.98732 10.5527 8.46258 11.028 8.46258 11.6121V14.1275C8.46263 14.7117 7.98736 15.1869 7.40317 15.1869ZM5.15277 13.8623H7.13803V11.8773H5.15277V13.8623Z" fill="#CDCDCD"/>
                                        <path d="M14.0475 15.1869H11.5321C10.9479 15.1869 10.4727 14.7117 10.4727 14.1275V11.6121C10.4727 11.028 10.9479 10.5527 11.5321 10.5527H14.0475C14.6317 10.5527 15.1069 11.028 15.1069 11.6121V14.1275C15.1069 14.7117 14.6317 15.1869 14.0475 15.1869ZM11.7973 13.8623H13.7823V11.8773H11.7973V13.8623Z" fill="#CDCDCD"/>
                                        <path d="M14.0475 8.53263H11.5321C10.9479 8.53263 10.4727 8.05745 10.4727 7.47335V4.95785C10.4727 4.3737 10.9479 3.89844 11.5321 3.89844H14.0475C14.6317 3.89844 15.1069 4.3737 15.1069 4.95785V7.47335C15.1069 8.05741 14.6317 8.53263 14.0475 8.53263ZM11.7973 7.20803H13.7823V5.22303H11.7973V7.20803Z" fill="#CDCDCD"/>
                                    </svg>
                                </div>
                            </div>
                            <div className='lg:w-2/3 lg:w-[90%]'>
                                <dl className="divide-y divide-solid py-6 px-12">
                                    <div className="py-2 flex justify-between">
                                        <dt className="text-sm font-light text-gray-400">Company</dt>
                                        <dd className="mt-1 text-sm text-gray-900 font-bold">{data.data.companyName}</dd>
                                    </div>
                                    <div className="py-2 flex justify-between">
                                        <dt className="text-sm font-light text-gray-400">Seller</dt>
                                        <dd className="mt-1 text-sm text-gray-900 font-bold">{data.data.sellers ? data.data.sellers[0].name : ''}</dd>
                                    </div>
                                    <div className="py-2 flex justify-between">
                                        <dt className="text-sm font-light text-gray-400">Buyer</dt>
                                        <dd className="mt-1 text-sm text-gray-900 font-bold">{data.data.buyers ? data.data.buyers[0].name : ''}</dd>
                                    </div>
                                    <div className="py-2 flex justify-between">
                                        <dt className="text-sm font-light text-gray-400">Amount</dt>
                                        <dd className="mt-1 text-sm text-gray-900 font-bold">{Formatters.formatBillions(data.data.amount)}</dd>
                                    </div>
                                    <div className="py-2 flex justify-between">
                                        <dt className="text-sm font-light text-gray-400">Transaction</dt>
                                        <dd className="mt-1 text-sm text-gray-900 font-bold">{data.data.transactionType}</dd>
                                    </div>
                                    <div className="py-2 flex justify-between">
                                        <dt className="text-sm font-light text-gray-400">Closed</dt>
                                        <dd className="mt-1 text-sm text-gray-900 font-bold">{Formatters.formatDate(data.data.closeDate)}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <section className='lg:w-2/3 w-[90%] mx-auto lg:mt-[15rem] md:mt-[40rem] mt-[35rem] mb-20'>
                        <h1 className='text-3xl font-poppins lg:text-left text-center font-bold mb-6'>NFT Trofied</h1>
                        <p className='text-sm text-neutral-400 font-light'>
                            Sed ullamcorper magna nisl leo pellentesque. Et auctor erat ut nisl lacus. Aliquam tellus pretium, natoque nunc nunc sed quis aliquet purus. Elit nulLorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl velit morbi ultrices sed. Erat consequat facilisis libero pellentesque malesuada nunc, egestas. At enim mi amet, quis. Mattis netus lacus augue malesuada tincidunt mauris ultrices vel. Ultricies et, in nec eget aliquet aliquam massa pellentesque. Luctus sed fringilla scelerisque malesuada neque facilisis. Est elit, metu
                        </p>
                    </section>
                    <section className='bg-white py-[70px]'>
                        <div className='lg:w-2/3 w-[90%] mx-auto'>
                            <h1 className='text-3xl lg:text-left text-center font-poppins font-bold mb-6'>Company Advisors</h1>
                            <CompanySection/>
                        </div>
                    </section>
                    <section className='py-[70px]'>
                        <div className='lg:w-2/3 w-[90%] mx-auto'>
                            <h1 className='text-3xl lg:text-left text-center font-poppins font-bold mb-6'>Buyside Advisors</h1>
                            <BuysideSection/>
                        </div>
                    </section>
                    <section className='bg-white py-20'>
                        <div className='lg:w-2/3 w-[90%] mx-auto'>
                            <h1 className='text-3xl lg:text-left text-center font-poppins font-bold mb-6'>People</h1>
                            <div className="flex flex-wrap items-center gap-[15px] px-4 py-2 bg-white font-medium text-neutral-400">
                                {
                                    data?.data?.holders?.map(holders => {
                                        return (
                                            <div key={holders?.id} className="w-[50px] flex relative flex-col items-center gap-[15px] justify-center">
                                                <span className="cursor-pointer h-10 w-10 rounded-full overflow-hidden bg-neutral-100">
                                                    {holders?.profile!.profileImage ? (
                                                        <img
                                                            onMouseOver={() => {userActive(holders)}}
                                                            alt="User Profile"
                                                            src={'https://trofied.s3.amazonaws.com/' + holders?.profile!.profileImage}
                                                            className="object-cover aspect-square rounded-full align-center h-10 w-10"
                                                        />
                                                    ) : (
                                                        <span onMouseOver={() => {userActive(holders)}} className="h-10 w-10 rounded-full overflow-hidden bg-neutral-100">
                                                            <svg
                                                                className="h-full w-full text-neutral-300"
                                                                fill="currentColor"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </span>

                                                <div className='absolute'>
                                                    {
                                                        (holders?.profile?.lastName && holders?.profile?.firstName) !== null ?
                                                            isHovering && (
                                                                <div className={`absolute z-10 text-center top-[25px] left-[-65px] ${holders?.id === usersIndex ? "flex flex-col items-center " : "hidden"}`}>
                                                                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M9.53779 0L18.3052 15.1856H0.770385L9.53779 0Z" fill="black"/>
                                                                    </svg>
                                                                    <div className='relative min-w-[130px] bottom-[5px] font-poppins text-[14px] p-[15px] font-[400] text-white minW-[130px] rounded-[4px] bg-black flex justify-center items-center'>
                                                                        {holders?.profile?.firstName} {holders?.profile?.lastName}
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </div>
                :
                <></>
            }
        </div>
    )
}