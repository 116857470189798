import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import Button from '../../common/Button';
import SmallGraphics from '../../../assets/animation/Small graphics';
import TickerTape from '../../../assets/animation/Ticker tape';
import Small from '../../../assets/animation/small';
import Checkmark from '../../../assets/animation/Checkmark';
import ArrowIcon from '../../../assets/animation/Arrow icon';
import WindowIcon from '../../../assets/animation/Window icon';
import SmallCircleGraphic from '../../../assets/animation/Logo';
import CalendarIcon from '../../../assets/animation/Calendar Icon';
import TempDocGuide from './TempDocGuide';

export default function Landing() {
  const navigate = useNavigate();

  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const [isCheckmarkHovered, setIsCheckmarkHovered] = useState(false);
  const [isWindowHovered, setIsWindowHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease-out-quart',
      once: true,
    });
  }, []);

  return (
    <>
      {/* <TempDocGuide /> */}
      <div>
        <section className="pt-12 md:pt-[70px] aos-section">
          <div className="container">
            <div className="flex flex-wrap gap-6 md:gap-0 flex-col md:flex-row items-center">
              <div
                className="w-full md:w-auto flex flex-col md:py-3"
                data-aos="fade-right"
              >
                <h1 className="h1 font-bold mb-6">
                  Celebrate <br className="hidden md:inline" />
                  Achievement.
                </h1>

                <h3 className="text-lg lg:text-xl leading-relaxed text-secondary md:max-w-[300px] xl:max-w-[360px] mb-8">
                  Trofied is a platform for validated virtual awards.
                </h3>

                <Button
                  children="Register"
                  className="btn btn-outline-primary btn-lg zoom-hover w-[150px] max-w-full"
                  onClick={() => navigate('/signup')}
                />
              </div>

              <div className="flex-1">
                <div
                  className="w-full max-w-[840px] aspect-[4.5/3] sm:aspect-[4.5/3] md:ml-auto"
                  data-aos="fade-left"
                >
                  <Small wrapperClassname="w-full h-full md:-my-[5%] md:ml-[7%] flex items-center justify-center md:justify-end" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative mt-[50px] md:mt-[90px] aos-section">
          <div className="absolute inset-y-0 inset-x-0 md:inset-x-2 lg:inset-x-4 xl:inset-x-8 2xl:inset-x-20 pointer-events-auto bg-info-800 bg-opacity-90 md:rounded-3xl"></div>

          <div className="container relative py-16 md:pt-[90px] md:pb-20">
            <div
              className="max-w-[600px] mx-auto text-center mb-8 md:mb-14"
              data-aos="fade"
            >
              <h2 className="h1 font-bold text-white mb-3 md:mb-4">
                How It Works
              </h2>

              <p className="lead font-normal text-white text-opacity-50">
                Our secure process ensures that each Trofy is a digitally
                unique, verified representation of an achievement.
              </p>
            </div>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:gap-[30px]">
              <div data-aos="fade-up" data-aos-delay="150">
                <div
                  className="h-full group py-8 px-6 md:py-11 md:px-8 border border-info hover:border-primary hover:shadow-dark rounded-xl transition-all duration-500"
                  onMouseEnter={() => setIsArrowHovered(true)}
                  onMouseLeave={() => setIsArrowHovered(false)}
                >
                  <div className="flex gap-6 mb-4 md:mb-5 items-center">
                    <div className="w-[70px] h-[70px]">
                      <ArrowIcon
                        isArrowHovered={isArrowHovered}
                        setIsArrowHovered={setIsArrowHovered}
                      />
                    </div>

                    <h6 className="h3 font-semibold text-white">Verify</h6>
                  </div>

                  <p className="lead font-light text-white text-opacity-50">
                    Trofied will confirm the details of the achievement with the
                    right people.
                  </p>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-delay="250">
                <div
                  className="h-full group py-8 px-6 md:py-11 md:px-8 border border-info hover:border-primary hover:shadow-dark rounded-xl transition-all duration-500"
                  onMouseEnter={() => setIsCheckmarkHovered(true)}
                  onMouseLeave={() => setIsCheckmarkHovered(false)}
                >
                  <div className="flex gap-6 mb-4 md:mb-5 items-center">
                    <div className="w-[70px] h-[70px]">
                      <Checkmark
                        isCheckmarkHovered={isCheckmarkHovered}
                        setIsCheckmarkHovered={setIsCheckmarkHovered}
                      />
                    </div>

                    <h6 className="h3 font-semibold text-white">Issue</h6>
                  </div>

                  <p className="lead font-light text-white text-opacity-50">
                    Trofied will distribute an immutable digital "Trofy" to
                    designated recipients using blockchain technology. Choose
                    from one of our standard designs or inquire for more
                    options.
                  </p>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-delay="350">
                <div
                  className="h-full group py-8 px-6 md:py-11 md:px-8 border border-info hover:border-primary hover:shadow-dark rounded-xl transition-all duration-500"
                  onMouseEnter={() => setIsWindowHovered(true)}
                  onMouseLeave={() => setIsWindowHovered(false)}
                >
                  <div className="flex gap-6 mb-4 md:mb-5 items-center">
                    <div className="w-[70px] h-[70px]">
                      <WindowIcon
                        isWindowHovered={isWindowHovered}
                        setIsWindowHovered={setIsWindowHovered}
                      />
                    </div>

                    <h6 className="h3 font-semibold text-white">Display</h6>
                  </div>

                  <p className="lead font-light text-white text-opacity-50">
                    Trofied's interactive platform is designed to showcase your
                    validated achievements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-16 md:mt-20 xl:mt-[104px] aos-section">
          <div className="container">
            <div className="flex flex-col gap-11 md:flex-row md:gap-10 items-center">
              <div className="flex-1 w-full md:w-1/2 lg:w-auto">
                <div
                  className="w-[474px] max-w-full mx-auto"
                  data-aos="fade-right"
                >
                  <SmallGraphics />
                </div>
              </div>

              <div
                className="w-full md:w-1/2 lg:w-auto max-w-[450px]"
                data-aos="fade-left"
              >
                <h3 className="h3-2 font-bold mb-4 md:mb-5">Build Your Case</h3>

                <p className="lead">
                  Your achievements. Your stories. Our proprietary platform was
                  developed to enable companies and individuals alike to collect
                  and share the stories of their accomplishments in a verified,
                  centralized and transparent way.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-16 md:mt-20 py-6 lg:py-12 bg-light aos-section">
          <div
            className="w-full h-[260px] md:h-[320px] xl:h-auto"
            data-aos="fade-up"
          >
            <TickerTape />
          </div>
        </section>

        <section className="relative py-16 md:pb-16 lg:py-36 bg-info-800 bg-opacity-90 overflow-hidden aos-section">
          <div className="container">
            <div className="flex gap-6 md:gap-0 flex-wrap items-center md:justify-end">
              <div className="hidden md:block w-full md:w-1/2 lg:w-auto md:absolute inset-y-0 md:-left-1 lg:left-0 3xl:left-[unset] md:right-1/2 aspect-[1/1] order-last md:order-first">
                  <SmallCircleGraphic wrapperClassname="w-full h-full transform scale-[1.2] md:scale-[1.04] xl:scale-[1.15] 3xl:scale-[1.25]" />
              </div>

              <div
                className="w-full md:w-1/2 md:max-w-[525px] xl:mr-1/12 relative"
                data-aos="fade"
              >
                <h3 className="h3 font-semibold text-light-100 mb-4 md:mb-5">
                  Secure. Verified.
                </h3>

                <p className="lead text-secondary-400">
                  A secure system ensures that awards are authentic. Any owner
                  of a Trofy has earned it with merit. This is particularly
                  important in a modern professional environment where people
                  are vying for recognition.
                </p>

                <p className="lead mt-5 md:mt-6 ">
                  The verification process validates the nature of what was
                  actually achieved. A participation award is just as 'real' as
                  a first place award; by enhancing transparency, we can ensure
                  that deserving individuals receive appropriate recognition for
                  their accomplishments.
                </p>

                <div className="text-left mt-6 md:mt-7">
                  <Button
                    onClick={() => navigate('/contact')}
                    children="Learn More"
                    className="btn btn-outline-white btn-lg text-sm w-[150px] max-w-full zoom-hover"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-14 md:pt-16 md:pb-8 aos-section">
          <div className="container">
            <div className="mx-auto w-full lg:w-11/12 xl:w-10/12">
              <div className="flex flex-wrap items-center gap-10">
                <div
                  className="w-full md:w-auto md:flex-1 py-2 md:pb-6"
                  data-aos="fade-up"
                >
                  <div className="flex flex-col items-center max-w-[400px] mx-auto text-center">
                    <div className="mb-[18px] w-[70px] h-[70px] inline-flex items-center justify-center text-white bg-primary rounded-2xl">
                      <svg
                        className="flex-shrink-0"
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35 0L0 19.6875L11.1853 23.8302L28.4376 7.65626L15.315 25.3597L15.3256 25.3637L15.3125 25.3597V35L21.5852 27.682L29.5313 30.625L35 0Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>

                    <p className="h6 font-bold">Stay Updated</p>

                    <p className="lead mt-5 mb-6">
                      Stay caught up with the most relevant news from the
                      Trofied team. We are building!
                    </p>

                    <div className="w-full max-w-[335px]">
                      <form>
                        <div className="relative">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="email"
                            className="field field-lg field-outline-white pr-12 rounded-2xs"
                            required={true}
                          />

                          <button
                            type="submit"
                            className="absolute top-4 right-5 cursor-pointer"
                          >
                            <svg
                              className="w-6 h-6 text-primary hover:text-black transition-colors duration-300"
                              width="23"
                              height="23"
                              viewBox="0 0 23 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23 0L0 12.9375L7.35036 15.6598L18.6875 5.03125L10.0641 16.665L10.0711 16.6676L10.0625 16.665V23L14.1846 18.191L19.4063 20.125L23 0Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <span className="hidden md:block self-stretch w-px bg-light-200 flex-none"></span>

                <div
                  className="w-full md:w-auto md:flex-1 py-2 md:pb-6"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="flex flex-col items-center max-w-[400px] mx-auto text-center">
                    <div className="mb-[18px] w-[70px] h-[70px] inline-flex items-center justify-center text-white bg-warning rounded-2xl">
                      <svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.3333 5H26.6667V1.66667C26.6667 1.22464 26.4911 0.800716 26.1785 0.488155C25.866 0.175595 25.442 0 25 0C24.558 0 24.1341 0.175595 23.8215 0.488155C23.5089 0.800716 23.3333 1.22464 23.3333 1.66667V5H10V1.66667C10 1.22464 9.82441 0.800716 9.51185 0.488155C9.19929 0.175595 8.77536 0 8.33333 0C7.89131 0 7.46738 0.175595 7.15482 0.488155C6.84226 0.800716 6.66667 1.22464 6.66667 1.66667V5H5C3.67392 5 2.40215 5.52679 1.46447 6.46447C0.526784 7.40215 0 8.67392 0 10V11.6667H33.3333V10C33.3333 8.67392 32.8066 7.40215 31.8689 6.46447C30.9312 5.52679 29.6594 5 28.3333 5Z"
                          fill="currentColor"
                        />
                        <path
                          d="M0 28.3333C0 29.6594 0.526784 30.9312 1.46447 31.8689C2.40215 32.8066 3.67392 33.3333 5 33.3333H28.3333C29.6594 33.3333 30.9312 32.8066 31.8689 31.8689C32.8066 30.9312 33.3333 29.6594 33.3333 28.3333V15H0V28.3333Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>

                    {/* <div className="text-white mb-[18px]">
                      <CalendarIcon />
                    </div> */}

                    <p className="h6 font-bold">Book a Meeting</p>
                    <p className="lead mt-5 mb-6">
                      Interested in learning more? <br />
                      Schedule a session with our team.
                    </p>

                    <Button
                      onClick={() => navigate('/contact')}
                      children="Book Now"
                      className="btn btn-outline-primary-warning btn-lg zoom-hover w-[180px] max-w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
