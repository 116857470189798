import React from 'react';
import CarouselUserDeal from "./CarouselUserDeal";
import UserComments from "./UserComments";
import MenuSelect from "./MenuSelect";
import MessageIcon from "../../../assets/icons/MessageIcon";
import ShareIconAnimated from "../../../assets/animation/ShareIcon";
import FavoriteIconAnimated from "../../../assets/animation/Favorite Icon";
interface IProfileInfoProps {
    deals: {
        id: number,
        image: string
    }
    date: Date
    dataProfileUser: {
        firstName?: string;
        lastName?: string;
        profileImage?: string;
        id?: number
    };
}
export default function RecentlyUser({deals, dataProfileUser, date}: IProfileInfoProps) {
    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) as any;

    const {
        firstName,
        lastName,
        profileImage,
        id
    } = dataProfileUser

    return (
        <div className="xl:w-[75%] bg-[#FCFCFC] min-h-[100vh] w-[100%] flex-column">
            <div className='bg-white'>
                <h1 className="ml-[28px] pt-[50px] text-[32px] mb-[42px] font-[600] font-poppins text-black">Recently Earned</h1>
                <CarouselUserDeal/>
            </div>

            <div className='flex bg-[#FCFCFC] items-center md:ml-[28px] sm:ml-[18px] ml-[8px] md:mr-[90px] sm:mr-[40px] mr-[10px] mt-[50px] md:gap-[55px] sm:gap-[35px] gap-[15px]'>
                <div className='md:w-[80%] sm:w-[60%] w-[50%] border-[.5px] border-solid border-[#D6D6D6]'></div>
                <MenuSelect/>
            </div>

            <div className="minH-[100vh] bg-[#FCFCFC] mb-[95px] sm:ml-[30px] xl:mr-[166px] sm:mr-[30px] mt-[30px] flex flex-col gap-[30px]">
                {
                    deals?.id ?
                        <div className=''>
                            <div key={id} className='hover:drop-shadow-right bg-white cursor-pointer rounded-[10px] py-[30px] border-[1px] border-solid border-[#F8F8F8] hover:shadow-[0_10px_40px_rgba(67, 67, 67, 0.05)] md:px-[45px] sm:px-[25px] px-[15px] flex flex-col sm:flex-row justify-between sm:items-center items-center gap-[22px]'>
                                <div>
                                    <div className='flex sm:flex-row flex-col sm:items-center items-start gap-[22px]'>
                                        {profileImage ? (
                                            <img
                                                alt="Profile"
                                                src={'https://trofied.s3.amazonaws.com/' + profileImage }
                                                className="object-cover aspect-square rounded-full align-center sm:h-[87px] h-[80px] sm:w-[87px] w-[80px]"
                                            />
                                        ) : (
                                            <span className="inline-block sm:h-[87px] h-[80px] sm:w-[87px] w-[80px] rounded-full overflow-hidden bg-neutral-100">
                                        <svg
                                            className="h-full w-full text-neutral-300"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </span>
                                        )}

                                        <div className="flex flex-col sm:gap-[16px] gap-[12px]">
                                            <div className='font-poppins text-black text-[16px] font-[600]'>
                                    <span className='mr-[8px] font-poppins text-black text-[19px] font-[700]'>
                                        {firstName} {lastName}
                                    </span>
                                                earned a new Trofy
                                            </div>
                                            <div className='font-poppins text-[#919191] text-[14px] font-[300]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                            <div className='font-poppins text-[#919191] text-[14px] font-[300]'>{ dateFormatter.format(new Date(date)) }</div>
                                        </div>
                                    </div>

                                    <div className="flex pt-[35px] items-center md:gap-[60px] sm:gap-[40px] gap-[30px]">
                                        <div className='cursor-pointer font-poppins text-black text-[16px] font-[600] flex items-center gap-[8px]'>
                                            <FavoriteIconAnimated/>
                                            335
                                        </div>

                                        <div className='cursor-pointer font-poppins text-black text-[16px] font-[600] flex items-center gap-[10px]'>
                                            <MessageIcon/>
                                            125
                                        </div>

                                        <div className='cursor-pointer font-poppins text-[#919191] text-[14px] font-[300] flex items-center gap-[10px]'>
                                            <ShareIconAnimated/>
                                            Share
                                        </div>
                                    </div>
                                </div>

                                <div className='flex relative items-center flex-col gap-[15px]'>
                                    <div className="w-[200px] h-[200px] bg-[#FCFCFC] p-[30px] rounded-[7px] border-[0.7px] border-solid border-[#FCFCFC]">
                                        <img src={'https://trofied.s3.amazonaws.com/' + deals?.image} alt='Trofy'/>
                                        <svg width="19" height="19" viewBox="0 0 19 19" className='absolute cursor-pointer right-[20px] bottom-[50px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.3934 5.96012C17.0277 5.96012 16.7311 5.66359 16.7311 5.29782V2.78515C16.7311 2.49352 16.4939 2.25624 16.2023 2.25624H13.6896C13.3239 2.25624 13.0273 1.9597 13.0273 1.59394C13.0273 1.22817 13.3239 0.931641 13.6896 0.931641H16.2023C17.2243 0.931641 18.0557 1.76313 18.0557 2.78515V5.29782C18.0557 5.66359 17.7592 5.96012 17.3934 5.96012Z" fill="#CDCDCD"/>
                                            <path d="M1.51386 5.96012C1.1481 5.96012 0.851562 5.66359 0.851562 5.29782V2.78515C0.851562 1.76313 1.68301 0.931641 2.70507 0.931641H5.21766C5.58342 0.931641 5.87995 1.22817 5.87995 1.59394C5.87995 1.9597 5.58342 2.25624 5.21766 2.25624H2.70507C2.41344 2.25624 2.17616 2.49352 2.17616 2.78515V5.29782C2.17616 5.66359 1.87967 5.96012 1.51386 5.96012Z" fill="#CDCDCD"/>
                                            <path d="M5.21766 18.1435H2.70507C1.68306 18.1435 0.851562 17.312 0.851562 16.29V13.7775C0.851562 13.4118 1.1481 13.1152 1.51386 13.1152C1.87963 13.1152 2.17616 13.4118 2.17616 13.7775V16.29C2.17616 16.5816 2.41339 16.8189 2.70507 16.8189H5.21766C5.58342 16.8189 5.87995 17.1154 5.87995 17.4812C5.87995 17.8469 5.58342 18.1435 5.21766 18.1435Z" fill="#CDCDCD"/>
                                            <path d="M16.2023 18.1435H13.6896C13.3239 18.1435 13.0273 17.8469 13.0273 17.4812C13.0273 17.1154 13.3239 16.8189 13.6896 16.8189H16.2023C16.4939 16.8189 16.7311 16.5816 16.7311 16.29V13.7775C16.7311 13.4118 17.0277 13.1152 17.3934 13.1152C17.7592 13.1152 18.0557 13.4118 18.0557 13.7775V16.29C18.0557 17.312 17.2242 18.1435 16.2023 18.1435Z" fill="#CDCDCD"/>
                                            <path d="M7.40317 8.53263H4.88754C4.30339 8.53263 3.82812 8.05745 3.82812 7.47335V4.95785C3.82812 4.3737 4.30339 3.89844 4.88754 3.89844H7.40317C7.98732 3.89844 8.46258 4.3737 8.46258 4.95785V7.47335C8.46263 8.05741 7.98736 8.53263 7.40317 8.53263ZM5.15277 7.20803H7.13803V5.22303H5.15277V7.20803Z" fill="#CDCDCD"/>
                                            <path d="M7.40317 15.1869H4.88754C4.30339 15.1869 3.82812 14.7117 3.82812 14.1275V11.6121C3.82812 11.028 4.30339 10.5527 4.88754 10.5527H7.40317C7.98732 10.5527 8.46258 11.028 8.46258 11.6121V14.1275C8.46263 14.7117 7.98736 15.1869 7.40317 15.1869ZM5.15277 13.8623H7.13803V11.8773H5.15277V13.8623Z" fill="#CDCDCD"/>
                                            <path d="M14.0475 15.1869H11.5321C10.9479 15.1869 10.4727 14.7117 10.4727 14.1275V11.6121C10.4727 11.028 10.9479 10.5527 11.5321 10.5527H14.0475C14.6317 10.5527 15.1069 11.028 15.1069 11.6121V14.1275C15.1069 14.7117 14.6317 15.1869 14.0475 15.1869ZM11.7973 13.8623H13.7823V11.8773H11.7973V13.8623Z" fill="#CDCDCD"/>
                                            <path d="M14.0475 8.53263H11.5321C10.9479 8.53263 10.4727 8.05745 10.4727 7.47335V4.95785C10.4727 4.3737 10.9479 3.89844 11.5321 3.89844H14.0475C14.6317 3.89844 15.1069 4.3737 15.1069 4.95785V7.47335C15.1069 8.05741 14.6317 8.53263 14.0475 8.53263ZM11.7973 7.20803H13.7823V5.22303H11.7973V7.20803Z" fill="#CDCDCD"/>
                                        </svg>
                                    </div>
                                    <div className='font-poppins text-[#919191] text-[10px] font-[400]'>12 People with this Trophy</div>
                                </div>
                            </div>
                        </div>
                    : null
                }
                <UserComments/>
            </div>
        </div>
    )
}