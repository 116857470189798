import BaseApi from './BaseApi';
import { IDataApi } from 'shared/src/api-interfaces/IDataApi';

export default class DataApi extends BaseApi {

	public static async getCountries(
		query: string
	): Promise<IDataApi.GetCountries.Response> {
		return BaseApi.makeRequest(`/data/countries?query=${query}`, 'GET');
	}

}
