import React from 'react';

interface Props {
  className?: string;
}

export default function QrCodeIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3934 5.96012C17.0277 5.96012 16.7311 5.66359 16.7311 5.29782V2.78515C16.7311 2.49352 16.4939 2.25624 16.2023 2.25624H13.6896C13.3239 2.25624 13.0273 1.9597 13.0273 1.59394C13.0273 1.22817 13.3239 0.931641 13.6896 0.931641H16.2023C17.2243 0.931641 18.0557 1.76313 18.0557 2.78515V5.29782C18.0557 5.66359 17.7592 5.96012 17.3934 5.96012Z"
        fill="currentColor"
      />
      <path
        d="M1.51386 5.96012C1.1481 5.96012 0.851562 5.66359 0.851562 5.29782V2.78515C0.851562 1.76313 1.68301 0.931641 2.70507 0.931641H5.21766C5.58342 0.931641 5.87995 1.22817 5.87995 1.59394C5.87995 1.9597 5.58342 2.25624 5.21766 2.25624H2.70507C2.41344 2.25624 2.17616 2.49352 2.17616 2.78515V5.29782C2.17616 5.66359 1.87967 5.96012 1.51386 5.96012Z"
        fill="currentColor"
      />
      <path
        d="M5.21766 18.1435H2.70507C1.68306 18.1435 0.851562 17.312 0.851562 16.29V13.7775C0.851562 13.4118 1.1481 13.1152 1.51386 13.1152C1.87963 13.1152 2.17616 13.4118 2.17616 13.7775V16.29C2.17616 16.5816 2.41339 16.8189 2.70507 16.8189H5.21766C5.58342 16.8189 5.87995 17.1154 5.87995 17.4812C5.87995 17.8469 5.58342 18.1435 5.21766 18.1435Z"
        fill="currentColor"
      />
      <path
        d="M16.2023 18.1435H13.6896C13.3239 18.1435 13.0273 17.8469 13.0273 17.4812C13.0273 17.1154 13.3239 16.8189 13.6896 16.8189H16.2023C16.4939 16.8189 16.7311 16.5816 16.7311 16.29V13.7775C16.7311 13.4118 17.0277 13.1152 17.3934 13.1152C17.7592 13.1152 18.0557 13.4118 18.0557 13.7775V16.29C18.0557 17.312 17.2242 18.1435 16.2023 18.1435Z"
        fill="currentColor"
      />
      <path
        d="M7.40317 8.53263H4.88754C4.30339 8.53263 3.82812 8.05745 3.82812 7.47335V4.95785C3.82812 4.3737 4.30339 3.89844 4.88754 3.89844H7.40317C7.98732 3.89844 8.46258 4.3737 8.46258 4.95785V7.47335C8.46263 8.05741 7.98736 8.53263 7.40317 8.53263ZM5.15277 7.20803H7.13803V5.22303H5.15277V7.20803Z"
        fill="currentColor"
      />
      <path
        d="M7.40317 15.1869H4.88754C4.30339 15.1869 3.82812 14.7117 3.82812 14.1275V11.6121C3.82812 11.028 4.30339 10.5527 4.88754 10.5527H7.40317C7.98732 10.5527 8.46258 11.028 8.46258 11.6121V14.1275C8.46263 14.7117 7.98736 15.1869 7.40317 15.1869ZM5.15277 13.8623H7.13803V11.8773H5.15277V13.8623Z"
        fill="currentColor"
      />
      <path
        d="M14.0475 15.1869H11.5321C10.9479 15.1869 10.4727 14.7117 10.4727 14.1275V11.6121C10.4727 11.028 10.9479 10.5527 11.5321 10.5527H14.0475C14.6317 10.5527 15.1069 11.028 15.1069 11.6121V14.1275C15.1069 14.7117 14.6317 15.1869 14.0475 15.1869ZM11.7973 13.8623H13.7823V11.8773H11.7973V13.8623Z"
        fill="currentColor"
      />
      <path
        d="M14.0475 8.53263H11.5321C10.9479 8.53263 10.4727 8.05745 10.4727 7.47335V4.95785C10.4727 4.3737 10.9479 3.89844 11.5321 3.89844H14.0475C14.6317 3.89844 15.1069 4.3737 15.1069 4.95785V7.47335C15.1069 8.05741 14.6317 8.53263 14.0475 8.53263ZM11.7973 7.20803H13.7823V5.22303H11.7973V7.20803Z"
        fill="currentColor"
      />
    </svg>
  );
}
