import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import json from './data.json';
import Lottie from 'react-lottie-player';

interface BgColorProps {
  style: React.CSSProperties;
  isSearchHovered: boolean;
  setIsSearchHovered: (isSearchHovered: boolean) => void;
}

export default function SearchIconAnimation({
  style,
  isSearchHovered,
  setIsSearchHovered,
}: BgColorProps) {
  return (
    // <Player
    //     style={{background: `${color}`, width: '44px', height: '44px', padding: '12px',  borderRadius: '50px', margin: 0}}
    //     src={json}
    //     loop
    //     autoplay
    // />
    <Lottie
      style={style}
      animationData={json}
      loop={true}
      play={isSearchHovered}
    //   goTo={50}
      className="lottie"
      onLoopComplete={(e) => {
        setIsSearchHovered(false);
      }}
    />
  );
}
