import { useEffect } from 'react';

/**
 * @TODO Termly doesn't support Typescript integration. 
 */ 

export function TermsAndConditions() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <section className='flex flex-col min-h-[700px] justify-center'>
      <div className="container px-0">
        <div
          name="termly-embed"
          data-id="76262dd4-8077-49ab-b58b-fbb70b8acae8"
          data-type="iframe"
        ></div>
      </div>
    </section>
  );
}

export function PrivacyPolicy() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <section className='flex flex-col min-h-[700px] justify-center'>
      <div className="container px-0">
        <div
          name="termly-embed"
          data-id="92e341f3-d607-4bfe-adb3-b7c120c022c9"
          data-type="iframe"
        ></div>
      </div>
    </section>
  );
}

export function Disclaimer() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <section className='flex flex-col min-h-[700px] justify-center'>
      <div className="container px-0">
        <div
          name="termly-embed"
          data-id="2c77651d-7938-47b2-98ca-91f2367f9df5"
          data-type="iframe"
        ></div>
      </div>
    </section>
  );
}
