import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext';
import Button from '../../common/Button';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import ErrorMessage from '../../common/ErrorMessage';
import logo from '../../../assets/svgs/logo.svg';
import TextInput from '../../common/TextInput';

interface ISignupForm {
    email: string;
    workEmail: string;
    password: string;
    confirmPassword: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.').required('Please enter an email.'),
    workEmail: Yup.string().email('Please enter a valid email.'),
    password: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/
        )
        .required(
            'Please enter a valid password. One uppercase, one lowercase, one special character and no spaces'
        ),
    confirmPassword: Yup.string()
        .required('Please reenter your above password.')
        .test(
            'password-match',
            'Passwords must match',
            function (value) {
                return this.parent.password === value
            }
        )
});

export default function Signup() {
    const auth = useContext(AuthContext);
    const [submitError, setSubmitError] = useState<string|undefined>(undefined);

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                className="mx-auto h-12 w-auto"
                src={logo}
                alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign up for an account</h2>
            <p className="mt-2 text-center text-sm text-gray-600">
                Already have an account?{' '}
                <Link className="text-blue-600 hover:text-blue-500 font-medium ml-1" to='/login' replace={true}>Log In</Link>
            </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <ErrorMessage error={submitError} />
                <Formik
                    initialValues={{
                        email: '',
                        workEmail: '',
                        password: '',
                        confirmPassword: ''
                    }}
                    onSubmit={async (values: ISignupForm, actions) => {
                        try {
                            await auth.signup(values);
                        } catch(err: any) {
                            actions.setSubmitting(false);
                            setSubmitError(err && err.message ? err.message : 'Something went wrong, please try again.');
                        }
                    }}
                    validateOnBlur={true}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<ISignupForm>) => {
                        const {
                            values, 
                            errors, 
                            touched, 
                            isSubmitting, 
                            handleChange, 
                            handleBlur
                        } = props;

                        return (
                            <Form className='space-y-6'>
                                <TextInput 
                                    label="Email"
                                    name="email"
                                    labelClass='block text-sm font-medium text-gray-700 mb-1'
                                    childClass='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    error={!!(errors.email && touched.email)}
                                    errorMessage={errors.email}
                                    value={values.email}
                                    infoMessage='We recommend using your personal email.'
                                />
                                
                                <TextInput 
                                    label="Work Email"
                                    name="workEmail"
                                    labelClass='block text-sm font-medium text-gray-700 mb-1'
                                    childClass='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!(errors.workEmail && touched.workEmail)}
                                    errorMessage={errors.workEmail}
                                    value={values.workEmail}
                                />

                                <TextInput
                                    label="Password"
                                    type="password"
                                    name="password"
                                    labelClass='block text-sm font-medium text-gray-700 mb-1'
                                    childClass='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    error={!!(errors.password && touched.password)}
                                    errorMessage={errors.password}
                                    value={values.password}
                                />

                                <TextInput
                                    label="Confirm Password"
                                    type="password"
                                    name="confirmPassword"
                                    labelClass='block text-sm font-medium text-gray-700 mb-1'
                                    childClass='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    error={!!(errors.confirmPassword && touched.confirmPassword)}
                                    errorMessage={errors.confirmPassword}
                                    value={values.confirmPassword}
                                />

                                <div>
                                    <Button 
                                        type='submit'
                                        className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-0'
                                        disabled={isSubmitting}
                                        children='Sign Up'
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            </div>
        </div>
    )
}
