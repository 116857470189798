import qs from 'qs';
import BaseApi from "./BaseApi";
import { IUsersApi } from 'shared/src/api-interfaces/IUsersApi';

export default class UsersApi extends BaseApi {

    // public static async listUsers(params: IUsersApi.List.Params): Promise<IUsersApi.List.Response> {
    public static async listUsers(params: IUsersApi.List.Params): Promise<IUsersApi.List.Response> {
        return this.makeRequest(`/users?${qs.stringify(params)}`, 'GET');
    }
}