import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import OAuthApi from '../../api/OAuthApi';
import { Menu, Transition } from '@headlessui/react';
import { CogIcon, LogoutIcon, ServerIcon } from '@heroicons/react/solid';
import {
  BsPencilSquare,
  BsGear,
  BsHddRack,
  BsLifePreserver,
  BsBoxArrowRight,
} from 'react-icons/bs';
import Button from './Button';
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import WalletIcon from '../../assets/animation/Wallet';

export default function AccountMenu({
  onEdit,
  walletAddress,
}: {
  onEdit?: () => void;
  walletAddress?: string;
}) {
  const { user, roles, logout } = useContext(AuthContext);
  const [isWalletIconHovered, setIsWalletIconHovered] = useState(false);
  const navigate = useNavigate();

  const { isLoading, error, data } = useQuery(
    'myCoinbaseProfile',
    OAuthApi.getUser
  );

  return (
    <div className="self-stretch flex items-center gap-3">
      <Menu as="div" className="self-stretch relative flex items-center">
        <Menu.Button className="leading-none h-10">
          {({ open }) => (
            <div
              className={`group inline-flex items-center ${
                open ? 'transform scale-[1.2]' : 'zoom-hover'
              }`}
            >
              {user!.profileImage ? (
                <img
                  alt="User Profile"
                  src={`https://trofied.s3.amazonaws.com/${user!.profileImage}`}
                  className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
                />
              ) : (
                <span className="inline-block w-10 h-10 bg-light-300 rounded-full overflow-hidden">
                  <svg
                    className="flex-shrink-0 h-full w-full text-secondary"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
              )}
            </div>
          )}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition duration-300 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition duration-300 transform"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-2"
        >
          <Menu.Items className="absolute z-[1000] top-full right-0 mt-1 max-h-72 w-52 overflow-auto text-sm font-semibold bg-white shadow-dark-lg rounded-lg">
            <Menu.Item>
              <button
                className="w-full flex items-center text-left gap-4 p-4 select-none text-secondary hover:text-black hover:bg-light-400 transition-colors duration-300"
                onClick={onEdit}
              >
                <BsPencilSquare className="h-5 w-5" />
                Edit Profile
              </button>
            </Menu.Item>

            <Menu.Item>
              <Link
                to="/settings"
                className="flex items-center gap-4 p-4 select-none text-secondary hover:text-black hover:bg-light-400 transition-colors duration-300"
              >
                <BsGear className="h-5 w-5" />
                Settings
              </Link>
            </Menu.Item>

            {roles && roles.includes('admin') && (
              <Menu.Item>
                <Link
                  to="/admin/dashboard"
                  className="flex items-center gap-4 p-4 select-none text-secondary hover:text-black hover:bg-light-400 transition-colors duration-300"
                >
                  <BsHddRack className="h-5 w-5" />
                  Admin Dashboard
                </Link>
              </Menu.Item>
            )}

            <Menu.Item>
              <Link
                to="/contact"
                className="flex items-center gap-4 p-4 select-none text-secondary hover:text-black hover:bg-light-400 transition-colors duration-300"
              >
                <BsLifePreserver className="h-5 w-5" />
                Help & Support
              </Link>
            </Menu.Item>

            <Menu.Item>
              <button
                className="w-full flex items-center gap-4 p-4 select-none text-secondary hover:text-black hover:bg-light-400 transition-colors duration-300"
                onClick={() => logout()}
              >
                <BsBoxArrowRight className="h-5 w-5" />
                Log Out
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>

      <button
        className="flex-shrink-0 leading-none hidden sm:inline-block"
        onMouseEnter={() => {
          setIsWalletIconHovered(true);
        }}
        onClick={() => navigate('/wallet')}
      >
        <WalletIcon
          style={{
            width: '28px',
            height: '28px',
          }}
          className={`${!walletAddress && !data?.data?.name ? '' : 'wallet-connected'} ${
            isWalletIconHovered ? 'wallet-hovered' : ''
          }`}
          isWalletIconHovered={isWalletIconHovered}
          setIsWalletIconHovered={setIsWalletIconHovered}
        />
      </button>
    </div>
  );
}
