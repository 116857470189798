import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import UserList from './UserList';
import MenuSelect from './MenuSelect';
import { useQuery } from 'react-query';
import UsersApi from '../../../api/UsersApi';
import SearchIcon from '../../../assets/icons/SearchIcon';
import CaretDownIcon from '../../../assets/icons/CaretDownIcon';
import { BsCheckLg } from 'react-icons/bs';

const people = [{ name: 'People' }, { name: 'Companies' }, { name: 'Trofies' }];

export default function Explore() {
  const { data } = useQuery('list-users', () => UsersApi.listUsers({}));
  const userLength = data?.data.length;
  const [selected, setSelected] = useState(people[0]);

  return (
    <>
      <section>
        <div className="bg-light py-14 md:py-20">
          <div className="container">
            <div className="flex justify-center">
              <div className="w-full text-center">
                <h1 className="h4 font-semibold mb-2 md:mb-4">Explore</h1>
                <p className="lead">
                  Search for People, Companies or Trofies using the search bar
                  below.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="-mt-8">
          <div className="container">
            <div className="flex justify-center">
              <div className="w-full max-w-[450px]">
                <div className="relative">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Button
                          className="group h-8 w-36 sm:w-[150px] flex items-center gap-2 px-4 border-l border-light-100 absolute top-3.5 right-12"
                          aria-expanded="false"
                        >
                          <CaretDownIcon
                            className={`flex-shrink-0 text-primary w-3 h-3 transform transition-transform duration-300 ${
                              open ? '-rotate-180 mt-1' : ''
                            }`}
                          />
                          <span className="text-base text-secondary group-hover:text-primary transition-colors duration-300">
                            {selected.name}
                          </span>
                        </Listbox.Button>

                        <Transition
                          as={Fragment}
                          show={open}
                          enter="transition duration-300 transform"
                          enterFrom="opacity-0 -translate-y-2"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition duration-300 transform"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 -translate-y-2"
                        >
                          <Listbox.Options
                            static
                            className="absolute z-[1000] mt-1 top-full right-10 max-h-60 w-40 overflow-auto text-sm bg-white shadow-dark-lg rounded-lg"
                          >
                            {people.map((person, personIdx) => (
                              <Listbox.Option
                                key={personIdx}
                                className={({ active }) =>
                                  `relative py-3 pl-10 pr-4 cursor-pointer select-none transition-colors duration-300 ${
                                    active
                                      ? 'bg-light-400 text-black'
                                      : 'text-secondary'
                                  }`
                                }
                                value={person}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={` ${
                                        selected ? 'text-primary' : ''
                                      }`}
                                    >
                                      {person.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                        <BsCheckLg className="h-5 w-5" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>

                  <button className="btn btn-primary btn-icon btn-circle absolute top-1.5 right-1.5">
                    <SearchIcon />
                  </button>

                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Search"
                    className="field field-white field-xl field-pill shadow-dark-lg pr-52"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-9 pb-14 md:pb-16">
          <div className="container">
            <div className="flex justify-center">
              <div className="w-full max-w-[920px]">
                <div className="flex gap-4 items-center mb-7">
                  <div className="flex-1">
                    <p className="lead font-light leading-tight">
                      {userLength} Results for People
                    </p>
                  </div>

                  <div className="w-auto">
                    <MenuSelect />
                  </div>
                </div>

                <UserList />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
