import { toast } from 'react-toastify';

export const sendSuccess = (message: string) => {
    return toast.success(message, {
        hideProgressBar: true
    });
}

export const sendError = (message: string) => {
    return toast.error(message, {
        hideProgressBar: true
    });
}
