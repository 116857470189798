import React, { useState } from 'react';
import animationData from './data.json';
// import Lottie from 'react-lottie';
import Lottie from 'react-lottie-player';

import { useInView } from 'react-intersection-observer';

export default function SmallCircleGraphic({
  wrapperClassname,
}: {
  wrapperClassname: string;
}) {
  const { ref, inView } = useInView();
  const [play, setPlay] = useState(true);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  //   renderer: 'html',
  // };

  return (
    // <Lottie options={defaultOptions} />

    <div ref={ref} className='w-full h-full'>
      <Lottie
        animationData={animationData}
        loop={play && inView ? 0 : false}
        play={true}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        renderer='html'
        className={wrapperClassname}
        onLoopComplete={() => {
          if (inView) {
            setPlay(false);
          } else {
            setPlay(true);
          }
        }}
      />
    </div>
  );
}
