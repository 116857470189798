import Lottie from 'react-lottie';
import animationData from './data.json';
// import Lottie from 'react-lottie-player';

export default function TickerTape() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Lottie
            options={defaultOptions}
        />
    );
}