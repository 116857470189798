import { useState } from 'react';
// import Lottie from 'react-lottie';
import animationData from './data.json';
import Lottie from 'react-lottie-player';
import { useInView } from 'react-intersection-observer';

export default function Small({
  wrapperClassname,
}: {
  wrapperClassname: string;
}) {
  const { ref, inView } = useInView();

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: false,
  //   isPaused: inView,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

  return (
    <>
      <div ref={ref} className='w-full h-full'>
        {/* <Lottie options={defaultOptions}   /> */}
        <Lottie
          animationData={animationData}
          loop={inView ? 1 : false}
          play={true}
          className={wrapperClassname}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }} 
        />
      </div>
    </>
  );
}
