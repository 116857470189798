import { useState } from 'react';

// import Lottie from 'react-lottie';
import animationData from './data.json';
import Lottie from 'react-lottie-player';

import { useInView } from 'react-intersection-observer';

export default function SmallGraphics() {
  const { ref, inView } = useInView();
  const [play, setPlay] = useState(true);

  //   const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: animationData,
  //     rendererSettings: {
  //       preserveAspectRatio: 'xMidYMid slice',
  //     },
  //   };

  return (
    // <Lottie
    //     options={defaultOptions}
    // />
    <div ref={ref}>
      <Lottie
        animationData={animationData}
        loop={play && inView ? 0 : false}
        play={true}
        className="lottie"
        onLoopComplete={() => {
          if (inView) {
            setPlay(false);
          } else {
            setPlay(true);
          }
        }}
      />
    </div>
  );
}
