import BaseApi from "./BaseApi";
import { IAuthApi } from 'shared/src/api-interfaces/IAuthApi';


export default class AuthApi extends BaseApi {

    public static async signup(body: IAuthApi.Signup.Request): Promise<IAuthApi.Signup.Response> {
        return this.makeRequest(`/auth/signup`, 'POST', body);
    }

    public static async login(body: IAuthApi.Login.Request): Promise<IAuthApi.Login.Response> {
        return this.makeRequest(`/auth/login`, 'POST', body);
    }

    public static async logout(): Promise<IAuthApi.Logout.Response> {
        return this.makeRequest(`/auth/logout`, 'POST');
    }

    public static async forgotPassword(body: IAuthApi.ForgotPassword.Request): Promise<IAuthApi.ForgotPassword.Response> {
        return this.makeRequest(`/auth/forgot-password`, 'POST', body);
    }

    public static async resetPassword(body: IAuthApi.ResetPassword.Request): Promise<IAuthApi.ResetPassword.Response> {
        return this.makeRequest(`/auth/reset-password`, 'POST', body);
    }

    public static async verifyToken(): Promise<IAuthApi.VerifyToken.Response> {
        return this.makeRequest(`/auth/verify-token`, 'GET');
    }
}