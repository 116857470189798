import React, { useState } from 'react';
import ArrowBottom from "../../../assets/icons/ArrowBottom";

export default function MenuSelect() {
    const data = [
        {value: "0", text: "New"},
        {value: "1", text: "Top"},
    ]

    const options = data.map((option) => {
        return (
            <option
                className="rounded-[6px] minW-[100px] color-black"
                style={{boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'}}
                value={option.value}
            >
                {option.text}
            </option>
        )
    })
    const [countryValue, setCountryValue] = useState<string>()

    return (
        <>
            <div>
                <div className='flex items-center gap-[12px]'>
                    <label className='font-poppins text-[14px] font-[300] text-[#919191]'>Sort By</label>
                    <select className='border-none focus:outline-0 cursor-pointer font-poppins text-[14px] font-[600] text-black' value={countryValue} onChange={(e) => setCountryValue(e.target.value)}>
                        {options}
                        <ArrowBottom/>
                    </select>
                </div>
            </div>
        </>
    );
}