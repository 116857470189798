import React, { useState } from 'react';
import { useQuery } from 'react-query';
import ProfileInfo from '../UserProfile/ProfileInfo';
import RecentlyUser from './RecentlyUser';
import Loading from '../../common/Loading';
import { useParams } from 'react-router-dom';
import UserProfileApi from '../../../api/UserProfileApi';

export default function UserProfile() {
  const params = useParams();

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { isLoading, data } = useQuery(
    'my-profile',
    UserProfileApi.getMyProfile
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!data || !data.data) {
    return <p>Failed to load Profile</p>;
  }

  return (
    <div className="relative min-h-screen justify-end font-poppins flex">
      <div className="absolute h-[95%] z-[9999] top-0 left-0">
        <ProfileInfo
          length={data?.data?.deals?.length}
          dataProfile={data.data.profile}
          onEdit={() => setEditModalOpen(true)}
        />
      </div>
      <RecentlyUser
        deals={data?.data?.deals?.[0]}
        dataProfileUser={data?.data?.profile}
        date={data?.data?.profile?.createdAt as any}
      />
    </div>
  );
}
