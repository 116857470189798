import React from 'react';

interface Props {
    colors: string;
}
export default function KeyboardArrowDownIcon({ colors }: Props) {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.238281" y="6.92188" width="2.44685" height="9.78741" rx="1.22343" transform="rotate(-45 0.238281 6.92188)" fill={colors}/>
            <rect x="12.3477" y="5.19141" width="2.44685" height="9.78741" rx="1.22343" transform="rotate(45 12.3477 5.19141)" fill={colors}/>
        </svg>
    )
}