import React from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import json from './animation.json';

export default function FavoriteIconAnimated() {
    return (
        <Player
            style={{width: '25px', height: '25px'}}
            src={json}
            loop
            autoplay
            className="lottie"
        />
    );
}