import BaseApi from "./BaseApi";
import { IOAuthApi } from 'shared/src/api-interfaces/IOAuthApi';

export default class OAuthApi extends BaseApi {

    public static async getUser(): Promise<IOAuthApi.GetCoinBaseUserData.Response> {
        return BaseApi.makeRequest(`/oauth/coinbase/user`, 'GET');
    }

    public static async getCoinbaseAuthURL(): Promise<IOAuthApi.GetCoinbaseAuthURL.Response> {
        return BaseApi.makeRequest(`/oauth/coinbase/link`, 'GET');
    }
}