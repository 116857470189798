import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import SettingsApi from '../../../api/SettingsApi';
import Loading from '../../common/Loading';
import CoinbaseProfile from './CoinbaseProfile';
import ConnectCoinbase from './ConnectCoinbase';
import { BsPencilSquare } from 'react-icons/bs';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import UserProfileApi from '../../../api/UserProfileApi';

export default function Settings() {
  // const { isLoading, error, data } = useQuery(
  //   'mySettings',
  //   SettingsApi.getMySettings
  // );

  // if (isLoading) {
  //   return <Loading />;
  // } else if (error || !data || !data.data) {
  //   console.log(error);
  //   return <p>Failed to load Settings...</p>;
  // }

  const { data: dataOfUserProfile } = useQuery(
    'my-profile',
    UserProfileApi.getMyProfile
  );

  return (
    <>
      {/* <div className="h-full mx-10 my-8 font-poppins">
        <h1 className="text-4xl font-bold mb-10">Settings</h1>
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900 pb-4 mb-5 border-b border-gray-200">
            Connected Accounts
          </h3>
          {data.data.coinbase.isConnected ? (
            <CoinbaseProfile />
          ) : (
            <ConnectCoinbase />
          )}
        </div>
      </div> */}

      <section>
        <div className="min-h-full flex flex-col justify-center py-12 lg:py-20 sm:px-6 lg:px-8 bg-gray-50">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              Settings
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6">
                <TextInput
                  label="Personal Email"
                  name="email"
                  labelClass="block text-sm font-medium text-gray-700 mb-1"
                  childClass="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={() => {}}
                  required={true}
                  infoMessage="We recommend using your personal email."
                />

                <TextInput
                  label="Work Email"
                  name="workEmail"
                  labelClass="block text-sm font-medium text-gray-700 mb-1"
                  childClass="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={() => {}}
                />

                <div className="relative">
                  <TextInput
                    label="Password"
                    type="password"
                    name="password"
                    labelClass="block text-sm font-medium text-gray-700 mb-1"
                    childClass="pr-9 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={() => {}}
                    isReadOnly={true}
                  />

                  <Link
                    to="/forgot-password"
                    className="absolute top-9 right-3 text-black hover:text-primary transition-colors duration-300"
                  >
                    <BsPencilSquare className="w-4 h-4" />
                  </Link>
                </div>

                <div className="relative">
                  <TextInput
                    label="Wallet Address"
                    name="walletAddress"
                    onChange={() => {}}
                    isReadOnly={true}
                    value={dataOfUserProfile?.data?.profile.walletAddress}
                    childClass="pr-9 "
                  />

                  <Link
                    to="/wallet"
                    className="absolute top-9 right-3 text-black hover:text-primary transition-colors duration-300"
                  >
                    <BsPencilSquare className="w-4 h-4" />
                  </Link>
                </div>

                <div>
                  <Button
                    // type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-0"
                    children="Save"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
