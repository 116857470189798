import React, { useContext, useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import ICompany from 'shared/src/model-interfaces/ICompany';
import ISchool from 'shared/src/model-interfaces/ISchool';
import Button from '../../common/Button';
import { useQuery } from 'react-query';
import CompaniesApi from '../../../api/CompaniesApi';
import AuthContext from '../../../context/AuthContext';
import { FormatIndentDecrease } from '@mui/icons-material';
import { PencilAltIcon } from '@heroicons/react/solid';
import {
  BsPencilSquare,
  BsXLg,
  BsPersonVcard,
  BsBriefcase,
  BsPinMap,
} from 'react-icons/bs';

interface IProfileInfoProps {
  length: number;
  onEdit: () => void;
  dataProfile: {
    firstName?: string;
    lastName?: string;
    profileImage?: string;
    jobTitle?: string;
    city?: string;
    id?: number;
    state?: string;
    countryName?: string;
    currentCompanyId?: number;
    schoolId?: number;
    schoolName?: string;
    company?: ICompany;
    school?: ISchool;
    walletAddress?: string;
  };
}

export default function ProfileInfo({
  length,
  dataProfile,
  onEdit,
}: IProfileInfoProps) {
  const {
    firstName,
    lastName,
    profileImage,
    currentCompanyId,
    jobTitle,
    city,
    state,
    countryName,
    company,
    school,
  } = dataProfile;

  console.log('length', length);

  const { data } = useQuery(`company:${currentCompanyId}`, () =>
    CompaniesApi.getCompany(currentCompanyId!)
  );
  const companyData = data?.data;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1023.98) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const auth = useContext(AuthContext);

  return (
    <>
      <div className="absolute z-[1] top-4 left-1/2 transform -translate-x-1/2 flex lg:hidden items-center gap-3">
        <button
          className={`flex-shrink-0 btn btn-solid-black btn-sm btn-icon btn-icon-sm btn-circle`}
          onClick={() => setIsOpen(true)}
        >
          <FormatIndentDecrease className="transform rotate-180" />
        </button>

        <h6 className="h6 font-semibold">Profile</h6>
      </div>

      <Transition show={isOpen} className="h-full">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="lg:hidden fixed z-[1040] inset-0 bg-black bg-opacity-50"
            onClick={() => setIsOpen(false)}
          ></div>
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform lg:transform-none"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform lg:transform-none"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="h-full fixed top-0 bottom-0 left-0 z-[1045] lg:static max-w-full w-[300px] md:w-[320px] lg:w-full bg-white shadow-dark-sm">
            <div className="absolute top-0 right-0 left-0 z-[1] flex gap-2 p-4 justify-end bg-white">
              {auth?.user ? (
                <Button
                  children={<BsPencilSquare className="bs-pencil-square" />}
                  className="btn btn-solid-black btn-sm btn-icon btn-icon-sm btn-circle"
                  onClick={onEdit}
                />
              ) : (
                <></>
              )}

              <button
                className={`lg:hidden btn btn-danger btn-sm btn-icon btn-icon-sm btn-circle`}
                onClick={() => setIsOpen(false)}
              >
                <BsXLg className="bs-xlg" />
              </button>
            </div>

            <div className="h-full relative overflow-y-auto hide-scrollbar">
              <div className="pb-12 pt-20 px-4 md:px-5 xl:px-12">
                <div className="text-center">
                  {profileImage ? (
                    <img
                      alt="User Profile"
                      src={`https://trofied.s3.amazonaws.com/${profileImage}`}
                      className={`inline-block w-[150px] h-[150px] xl:w-[188px] xl:h-[188px] object-cover rounded-full`}
                    />
                  ) : (
                    <span
                      className={`inline-block w-[150px] h-[150px] xl:w-[188px] xl:h-[188px] bg-light-300 rounded-full overflow-hidden`}
                    >
                      <svg
                        className="flex-shrink-0 h-full w-full text-secondary"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}

                  <h1 className="h5 mt-4 md:mt-6 font-semibold">
                    {firstName} {lastName}{' '}
                  </h1>
                </div>

                <hr className="border-light-300 my-7" />

                <ul className="flex flex-col gap-4">
                  <li className="flex items-start justify-between gap-5">
                    <div className="inline-flex items-center gap-2">
                      
                      <BsPersonVcard className="flex-shrink-0 w-4 h-auto text-secondary" />

                      <span className="text-sm font-light text-secondary">
                        Title
                      </span>
                    </div>

                    <p className="text-sm font-medium text-dark text-right">
                      {jobTitle}
                    </p>
                  </li>

                  <li className="flex items-start justify-between gap-5">
                    <div className="inline-flex items-center gap-2">
                      
                      <BsBriefcase className="flex-shrink-0 w-4 h-auto text-secondary" />

                      <span className="text-sm font-light text-secondary">
                        Job
                      </span>
                    </div>

                    <p className="text-sm font-medium text-dark text-right">
                      {company ? company.name : ''}
                    </p>
                  </li>

                  <li className="flex items-start justify-between gap-5">
                    <div className="inline-flex items-center gap-2">
                      
                      <BsPinMap className="flex-shrink-0 w-4 h-auto text-secondary" />

                      <span className="text-sm font-light text-secondary">
                        Location
                      </span>
                    </div>

                    <p className="text-sm font-medium text-dark text-right">
                      {city} {state && `, ${state}`}<br/>{countryName}
                    </p>
                  </li>
                </ul>

                <hr className="border-light-300 my-7" />

                <ul className="flex flex-col gap-5">
                  <li className="flex items-center gap-3">
                    <svg
                      width="39"
                      height="39"
                      className="flex-shrink-0 w-10 h-10"
                      viewBox="0 0 39 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="38.4568"
                        height="38.4568"
                        rx="5.85212"
                        fill="#7870FF"
                      />
                      <path
                        d="M10 20.3023C10 18.6689 11.3241 17.3447 12.9575 17.3447H15.9151C17.5485 17.3447 18.8726 18.6689 18.8726 20.3023V26.9567H10V20.3023Z"
                        fill="#564DED"
                      />
                      <path
                        d="M17.3945 20.3023C17.3945 18.6689 18.7187 17.3447 20.3521 17.3447H23.3096C24.943 17.3447 26.2672 18.6689 26.2672 20.3023V26.9567H17.3945V20.3023Z"
                        fill="white"
                      />
                      <circle
                        cx="14.4341"
                        cy="12.9087"
                        r="2.95754"
                        fill="#564DED"
                      />
                      <circle
                        cx="21.8325"
                        cy="12.9087"
                        r="2.95754"
                        fill="white"
                      />
                    </svg>

                    <div>
                      <p className="text-xl leading-none font-semibold">0</p>
                      <p className="text-xs text-secondary">Followers</p>
                    </div>
                  </li>

                  <li className="flex items-center gap-3">
                    <svg
                      width="40"
                      height="39"
                      className="flex-shrink-0 w-10 h-10"
                      viewBox="0 0 40 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.707031"
                        width="38.4568"
                        height="38.4568"
                        rx="5.85212"
                        fill="#DDAA00"
                      />
                      <path
                        d="M14.0703 13.3763L25.7743 9.19629V28.4243H14.0703L14.0703 13.3763Z"
                        fill="white"
                      />
                      <rect
                        x="10.7266"
                        y="25.0811"
                        width="18.392"
                        height="3.344"
                        fill="#DD8400"
                      />
                    </svg>

                    <div>
                      <p className="text-xl leading-none font-semibold">
                        {length}
                      </p>
                      <p className="text-xs text-secondary">Trofies</p>
                    </div>
                  </li>
                </ul>

                <hr className="border-light-300 my-7" />

                <ul className="flex flex-col gap-5">
                  <li>
                    <p className="mb-1 text-sm font-light text-secondary">
                      Work
                    </p>

                    {companyData && companyData.logo ? (
                      <Link
                        to={`/company/${currentCompanyId}`}
                        className="p-2 inline-flex justify-center items-center max-w-full w-[150px] h-[84px] border border-light-300 rounded-sm"
                      >
                        <img
                          alt="Work"
                          src={
                            companyData.logo
                              ? 'https://trofied.s3.amazonaws.com/' +
                                companyData.logo
                              : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'
                          }
                          className="flex-shrink-0 max-w-full max-h-full object-contain"
                        />
                      </Link>
                    ) : (
                      <p className="text-sm font-medium text-dark">
                        {companyData?.name}
                      </p>
                    )}
                  </li>

                  <li>
                    <p className="mb-1 text-sm font-light text-secondary">
                      Education
                    </p>

                    {school && school.logo ? (
                      <div className="p-2 inline-flex justify-center items-center max-w-full w-[150px] h-[84px] border border-light-300 rounded-sm">
                        <img
                          alt="Alma Mater"
                          src={
                            school.logo
                              ? 'https://trofied.s3.amazonaws.com/' +
                                school.logo
                              : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'
                          }
                          className="flex-shrink-0 max-w-full max-h-full object-contain"
                        />
                      </div>
                    ) : (
                      <p className="text-sm font-medium text-dark">
                        {school?.name}
                      </p>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </>
  );
}
