import React from 'react';

export default function CaretDownIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="4.41797"
        width="1.56251"
        height="6.25002"
        rx="0.781253"
        transform="rotate(-45 0 4.41797)"
        fill="currentColor"
      />
      <rect
        x="7.73438"
        y="3.31348"
        width="1.56251"
        height="6.25002"
        rx="0.781253"
        transform="rotate(45 7.73438 3.31348)"
        fill="currentColor"
      />
    </svg>
  );
}
