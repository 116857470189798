import BaseApi from "./BaseApi";
import { IContactApi } from 'shared/src/api-interfaces/IContactApi';


export default class ContactApi extends BaseApi {

    public static async sendMessage(
        body: IContactApi.SendMessage.Request
    ): Promise<IContactApi.SendMessage.Response> {
        return BaseApi.makeRequest(`/contact`, 'POST', body);
    }
}