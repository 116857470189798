import React from 'react';

export default function FollowerIcon() {
    return (
        <svg className="mr-3" width="20px" height="24px" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="38.4568" height="38.4568" rx="5.85212" fill="#7870FF"/>
            <path d="M10 20.3023C10 18.6689 11.3241 17.3447 12.9575 17.3447H15.9151C17.5485 17.3447 18.8726 18.6689 18.8726 20.3023V26.9567H10V20.3023Z" fill="#564DED"/>
            <path d="M17.3945 20.3023C17.3945 18.6689 18.7187 17.3447 20.3521 17.3447H23.3096C24.943 17.3447 26.2672 18.6689 26.2672 20.3023V26.9567H17.3945V20.3023Z" fill="white"/>
            <circle cx="14.4341" cy="12.9087" r="2.95754" fill="#564DED"/>
            <circle cx="21.8325" cy="12.9087" r="2.95754" fill="white"/>
        </svg>

    )
}