import React from 'react';

interface Props {
    onClick: () => void
}
export default function ArrowRightIcon({onClick}: Props) {
    return (
        <div onClick={onClick} style={{background: 'linear-gradient(90deg, rgba(252, 252, 252, 0.9) 40.29%, rgba(252, 252, 252, 0) 108.76%)'}} className="animate-[wave_5s_ease-in-out_2] cursor-pointer absolute right-0 top-[0] z-[100] w-[140px] h-[385px]">
            <svg className='absolute top-[70%] right-[25%]' width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8603 14.1768L1.98437 0.33925C1.52975 -0.113854 0.793695 -0.113092 0.339829 0.341594C-0.113685 0.796221 -0.112513 1.53268 0.342173 1.98614L13.3921 15.0001L0.341704 28.0139C-0.112923 28.4674 -0.114095 29.2034 0.33936 29.6581C0.566879 29.886 0.864945 30 1.16301 30C1.46031 30 1.75721 29.8868 1.98432 29.6604L15.8603 15.8233C16.0792 15.6054 16.2021 15.309 16.2021 15.0001C16.2021 14.6912 16.0789 14.395 15.8603 14.1768Z" fill="black"/>
            </svg>
        </div>
    )
}