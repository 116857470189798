import { useEffect, useState } from "react";
import NftApi from "../../../api/NftApi";
import GetConnectedWallet from "../../../utils/WalletFunctions/GetConnectedWallet";
import {
  PencilAltIcon,
  PlusIcon as PlusIconMini,
} from "@heroicons/react/solid";
import NftInfo from "shared/src/model-interfaces/INftInfo";
import { sendError, sendSuccess } from "../../../lib/helpers/toast";
import INftListParams from "../../../../../shared/src/api-interfaces/INftListParams";
import Button from "../../../components/common/Button";
import Table from "../../../components/common/Table";
import TrofyDetailsForm from "./TrofyDetailsForm";
import Loading from "../../../components/common/Loading";

interface ITrofiesProps {
  onEdit: () => void;
}

export default function AdminTrofies({ onEdit }: ITrofiesProps) {
  //State variables
  const [data, setData] = useState<NftInfo[]>();
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [currentTrophiedId, setCurrentTrophiedId] = useState(undefined);
  const [isLoading, setLoading] = useState(false);

  const columns = [
    {
      id: "trofies-table",
      Header: "",
      columns: [
        {
          Header: "Token Id",
          accessor: "id",
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Token Count",
          accessor: "totalCount",
        },
        {
          Header: "Unclaimed",
          accessor: "count",
        },
        {
          Header: "More Details",
          id: "edit",
          Cell: ({ row }: any) => {
            return (
              <Button
                children=""
                icon={<PencilAltIcon className="h-5 w-5 my-auto mr-2" />}
                onClick={() => {
                  setCurrentTrophiedId(row.values.id);
                  setEditFormOpen(true);
                  console.log("data", data![row.values.id]);
                }}
                className="bg-transparent hover:text-blue-500 text-gray-700"
              />
            );
          },
        },
      ],
    },
  ];

  useEffect(() => {
    const getNfts = async () => {
      await fetchConnectedWallet();
    };
    getNfts();
    addWalletListener();
  }, []);

  const fetchConnectedWallet = async () => {
    setLoading(true);
    const { success, address, status } = await GetConnectedWallet();
    if (success) {
      await fetchNftsByWallet(address!);
    } else {
      setData(undefined);
      console.error("Error fetching wallet");
      sendError(status);
      setLoading(false);
    }
  };

  function addWalletListener() {
    if ((window as any).ethereum) {
      (window as any).ethereum.on("accountsChanged", () => {
        fetchConnectedWallet();
      });
      (window as any).ethereum.on("chainChanged", () => {
        fetchConnectedWallet();
      });
    }
  }

  async function fetchNftsByWallet(address: string) {
    try {
      const params: INftListParams = { wallet: address };
      let nfts = await NftApi.getOwnedNfts(params);
      nfts && setData(nfts.data);
    } catch (err: any) {
      console.log(err);
      sendError("Error: " + err.message);
    }
    setLoading(false);
  }

  return (
    <div className="flex-column justify-center">
      <header>
        <div className="flex justify-center mx-auto max-w-7xl sm:px-6 lg:px-8 my-4">
          <h1 className="px-4 text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Trofy List
          </h1>
          <button
            type="button"
            className="relative inline-flex items-center rounded-full border border-transparent bg-sky-600 p-2 text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onEdit}
          >
            <PlusIconMini className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </header>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-4/5 mt-2 mx-auto">
          <Table columns={columns} data={data ? data : []} />
        </div>
      )}
      {currentTrophiedId && (
        <TrofyDetailsForm
          open={editFormOpen}
          setOpen={setEditFormOpen}
          trofy={data![currentTrophiedId]}
        />
      )}
    </div>
  );
}
