import React from 'react';

export default function PeopleBlockIcon() {
    return (
        <svg width="46px" height="46px" viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-700" fillRule="nonzero">
                    <rect id="Rectangle-path" fill="#7870FF" x="0" y="0" width="46" height="46" rx="5.09406"></rect>
                    <path d="M12.3672,23.7371 C12.3672,22.2756 13.552,21.0908 15.0135,21.0908 L20.63,21.0908 C22.0915,21.0908 23.2763,22.2756 23.2763,23.7371 L23.2763,32.909 L12.3672,32.909 L12.3672,23.7371 Z" id="Shape" fill="#564DED"></path>
                    <path d="M21.4609,23.7371 C21.4609,22.2756 22.6457,21.0908 24.1072,21.0908 L29.7238,21.0908 C31.1853,21.0908 32.37,22.2756 32.37,23.7371 L32.37,32.909 L21.4609,32.909 L21.4609,23.7371 Z" id="Shape" fill="#FFFFFF"></path>
                    <circle id="Oval" fill="#564DED" cx="17.82" cy="15.6354" r="3.63636"></circle>
                    <circle id="Oval" fill="#FFFFFF" cx="26.9098" cy="15.6354" r="3.63636"></circle>
                </g>
            </g>
        </svg>
    )
}