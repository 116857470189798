import React from 'react';
import AsyncSelect from 'react-select/async';

interface Props {
  name: string;
  value?: string | number | undefined;
  error?: boolean;
  success?: boolean;
  required?: boolean;
  asyncSelect?: boolean;
  label?: string;
  type?: string;
  errorMessage?: string;
  successMessage?: string;
  infoMessage?: string;
  parentClass?: string;
  labelClass?: string;
  childClass?: string;
  defaultValue?: string | number;
  placeholder?: string;
  defaultInputValue?: string;
  onChange: (e: any) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  loadOptions?: any;
  useDefaultStyles?: boolean;
  icon?: JSX.Element;
  iconRight?: boolean;
  isTextArea?: boolean;
  rows?: number;
  isFileInput?: boolean;
  isReadOnly?: boolean;
}

export default function TextField(props: Props) {
  const {
    name,
    error = false,
    success = false,
    required = false,
    asyncSelect = false,
    value,
    label,
    type = 'text',
    errorMessage = '',
    successMessage = '',
    parentClass = '',
    labelClass = '',
    childClass = '',
    defaultValue = '',
    placeholder = '',
    defaultInputValue,
    infoMessage,
    onChange,
    onBlur,
    loadOptions,
    useDefaultStyles = true,
    icon,
    iconRight = false,
    isTextArea = false,
    rows = 4,
    isFileInput = false,
    isReadOnly = false,
  } = props;

  return (
    <div
      className={
        parentClass + `${useDefaultStyles ? ' mb-4 text-gray-700' : ''}`
      }
    >
      {label && (
        <label
          className={labelClass + `${useDefaultStyles ? ' block mb-1' : ''}`}
          htmlFor="field"
        >
          {label}
          {required && <span className="text-red-600 ml-1">*</span>}
        </label>
      )}
      {asyncSelect ? (
        <AsyncSelect
          // defaultMenuIsOpen
          // menuIsOpen
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          defaultInputValue={defaultInputValue}
          defaultValue={defaultValue}
          className={
            'text-base placeholder-gray-600 border border-slate-400 rounded-lg focus:shadow-outline bg-white'
          }
          styles={{
            valueContainer: (provided, state) => ({
              ...provided,
              border: 'none',
            }),
            control: (provided, state) => ({
              ...provided,
              border: 'none',
              backgroundColor: 'transparent',
              borderRadius: 8,
            }),
          }}
        />
      ) : icon && iconRight ? (
        <div className="flex m-auto w-[334px] h-[56px] border border-[#E1E1E1] rounded">
          <input
            className={
              childClass +
              `${
                useDefaultStyles
                  ? `w-full h-10 px-3 text-base placeholder-gray-600 border ${
                      error
                        ? 'border-red-700'
                        : success
                        ? 'border-green-700'
                        : 'border-slate-400'
                    } rounded-lg focus:shadow-outline}`
                  : ''
              }`
            }
            placeholder={placeholder}
            type={type}
            id="field"
            name={name}
            aria-describedby="fieldMessage"
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
          />
          <i className="my-auto align-middle mr-4">{icon}</i>
        </div>
      ) : icon ? (
        <div className="flex my-auto  h-[42px] w-[232px] bg-[#FCFCFC] rounded-full">
          <i className="my-auto align-middle ml-4">{icon}</i>
          <input
            className={
              childClass +
              `${
                useDefaultStyles
                  ? `w-full h-10 px-3 text-base placeholder-gray-600 border ${
                      error
                        ? 'border-red-700'
                        : success
                        ? 'border-green-700'
                        : 'border-slate-400'
                    } rounded-lg focus:shadow-outline}`
                  : ''
              }`
            }
            placeholder={placeholder}
            type={type}
            id="field"
            name={name}
            aria-describedby="fieldMessage"
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      ) : isTextArea ? (
        <textarea
          className={
            childClass +
            `${
              useDefaultStyles
                ? `w-full h-10 px-3 text-base placeholder-gray-600 border ${
                    error
                      ? 'border-red-700'
                      : success
                      ? 'border-green-700'
                      : 'border-slate-400'
                  } rounded-lg focus:shadow-outline}`
                : ''
            }`
          }
          placeholder={placeholder}
          rows={rows}
          id="field"
          name={name}
          aria-describedby="fieldMessage"
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : isFileInput ? (
        <input
          className={
            childClass +
            `${
              useDefaultStyles
                ? `w-full h-10 px-3 text-base placeholder-gray-600 border ${
                    error
                      ? 'border-red-700'
                      : success
                      ? 'border-green-700'
                      : 'border-slate-400'
                  } rounded-lg focus:shadow-outline}`
                : ''
            }`
          }
          placeholder={placeholder}
          type="file"
          id="field"
          name={name}
          aria-describedby="file_input_help"
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          accept="image/*"
        />
      ) : isReadOnly ? (
        <input
          className={
            childClass +
            `${
              useDefaultStyles
                ? `w-full h-10 px-3 text-base placeholder-gray-600 border ${
                    error
                      ? 'border-red-700'
                      : success
                      ? 'border-green-700'
                      : 'border-slate-400'
                  } rounded-lg focus:shadow-outline}`
                : ''
            }`
          }
          placeholder={placeholder}
          type={type}
          id="field"
          name={name}
          aria-describedby="fieldMessage"
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={isReadOnly}
        />
      ) : (
        <input
          className={
            childClass +
            `${
              useDefaultStyles
                ? `w-full h-10 px-3 text-base placeholder-gray-600 border ${
                    error
                      ? 'border-red-700'
                      : success
                      ? 'border-green-700'
                      : 'border-slate-400'
                  } rounded-lg focus:shadow-outline}`
                : ''
            }`
          }
          placeholder={placeholder}
          type={type}
          id="field"
          name={name}
          aria-describedby="fieldMessage"
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {error ? (
        <span className="text-xs text-red-700" id="fieldMessage">
          {errorMessage}
        </span>
      ) : success ? (
        <span className="text-xs text-green-700" id="fieldMessage">
          {successMessage}
        </span>
      ) : infoMessage ? (
        <span className="text-xs text-gray-600" id="fieldMessage">
          {infoMessage}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}
