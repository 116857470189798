import { ChainId } from '../ChainId.Constants';

export default async function GetConnectedWallet(): Promise<{
	success: boolean;
	address?: string;
	status: string;
}> {
	if ((window as any).ethereum) {
		try {
			const addressArray = await (window as any).ethereum.request({
				method: 'eth_requestAccounts',
			});
			if (addressArray.length) {
				return {
					success: true,
					address: addressArray[0],
					status: 'Wallet connected!',
				};
			}
			return {
				success: false,
				status: 'No connected wallet',
			};
		} catch (err: any) {
			console.error('Error retrieving wallet', err);
			return {
				success: false,
				status: 'Error retrieving wallet: ' + err.message,
			};
		}
	}
	return {
		success: false,
		status: 'Please install metamask or coinbase wallet',
	};
}