import React from 'react';

export default function LocationBlockIcon() {
    return (
        <svg width="46px" height="46px" viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-1030" fillRule="nonzero">
                    <rect id="Rectangle-path" fill="#FF7092" x="0" y="0" width="46" height="46" rx="5.09406"></rect>
                    <path d="M22.9058,9.00021 L22.8643,9 C17.4251,9 13,13.5038 13,19.0399 C13,22.075 14.5244,24.4423 16.2228,26.7997 L22.8511,36 C22.8511,36 29.1161,27.3437 29.508,26.7999 C31.2064,24.4425 32.7308,22.0752 32.7308,19.0401 C32.7308,13.5176 28.3273,9.02204 22.9058,9.00021 Z M22.8655,25.1785 C19.5395,25.1785 16.8337,22.4248 16.8337,19.0399 C16.8337,15.6552 19.5395,12.9015 22.8655,12.9015 C26.1915,12.9015 28.8973,15.6552 28.8973,19.0399 C28.8973,22.4248 26.1913,25.1785 22.8655,25.1785 Z" id="Shape" fill="#FFFFFF"></path>
                    <circle id="Oval" fill="#ED4C73" cx="22.8672" cy="18.8652" r="6.75"></circle>
                </g>
            </g>
        </svg>
    )
}