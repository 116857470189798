import { useState, useEffect } from 'react';
import animationData from './animation.json';
import Lottie from 'react-lottie-player';
import { Transition } from '@headlessui/react';

interface loadingProps {
  wrapperClassName?: string;
  lottieClassName?: string;
  isLoadingTime?: boolean;
  loadingTime?: number;
}

export default function Loading({
  wrapperClassName = 'flex items-center justify-center w-full h-layout overflow-hidden',
  lottieClassName = 'w-[500px] h-[500px] md:w-[600px] md:h-[600px]',
  isLoadingTime = false,
  loadingTime = 3000,
}: loadingProps) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, loadingTime);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {isLoadingTime ? (
        <Transition
          show={isOpen}
          enter="transition-opacity ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={wrapperClassName}>
            <Lottie
              className={lottieClassName}
              animationData={animationData}
              loop={true}
              play={true}
              rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice',
              }}
              renderer="svg"
            />
          </div>
        </Transition>
      ) : (
        <div className={wrapperClassName}>
          <Lottie
            className={lottieClassName}
            animationData={animationData}
            loop={true}
            play={true}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            renderer="svg"
          />
        </div>
      )}
    </>
  );
}
