// @ts-ignore-start
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import UsersApi from '../../../api/UsersApi';
import Button from '../../common/Button';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import Loading from '../../common/Loading';

let PageSize = 8;
export default function UserList() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, data } = useQuery('list-users', () =>
    UsersApi.listUsers({})
  );

  
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data?.data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data?.data?.length]);


  if (isLoading) {
    return (
      <Loading wrapperClassName="flex items-center justify-center w-full h-52 overflow-hidden" />
    );
  }
  if (!data || !data.data) {
    return (
      <div>
        <Button
          children={<span className="mx-auto px-2">Please Login First</span>}
          size="large"
          className="mx-auto px-[16px] w-[200px] block border border-solid border-[#0078EF] text-black text-center rounded-[5px] hover:bg-black hover:text-white hover:border-black"
          onClick={() => navigate('/login')}
        />
      </div>
    );
  }

  return (
    <>
      <ul className="flex items-center flex-col w-full gap-5 md:gap-4">
        {currentTableData &&
          currentTableData.map((data) => {
            return (
              <li
                // onClick={() => navigate(`/feed/${data?.id}`)}
                onClick={() => navigate(`/coming-soon`)}
                key={data.id}
                className="w-full p-3.5 flex flex-col md:flex-row gap-6 md:gap-8 md:items-center md:justify-between cursor-pointer bg-white border border-light-100 hover:border-primary shadow-none hover:shadow-dark-lg rounded-3xl transition-all duration-300"
              >
                <div className="flex items-center gap-4 sm:gap-7 max-w-full">
                  {data.profile!.profileImage ? (
                    <img
                      alt="User Profile"
                      src={
                        'https://trofied.s3.amazonaws.com/' +
                        data?.profile?.profileImage
                      }
                      className="flex-shrink-0 object-cover w-20 h-20 md:w-24 md:h-24 rounded-full"
                    />
                  ) : (
                    <span className="flex-shrink-0 inline-block w-20 h-20 md:w-24 md:h-24 bg-light-100 rounded-full overflow-hidden">
                      <svg
                        className="flex-shrink-0 h-full w-full text-light-700"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}

                  <div>
                    <h1 className="h5 font-semibold">
                      {data?.profile?.firstName} {data?.profile?.lastName}{' '}
                    </h1>
                    <p className="mt-1 text-sm font-light text-secondary-400">
                      Associate at {data?.profile?.company?.name}
                    </p>
                  </div>
                </div>

                <div className="flex items-center max-w-full">
                    <div className="flex-1 flex items-center gap-3 w-40">
                      <svg
                        width="39"
                        height="39"
                        className="flex-shrink-0 w-10 h-10"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="38.4568"
                          height="38.4568"
                          rx="5.85212"
                          fill="#7870FF"
                        />
                        <path
                          d="M10 20.3023C10 18.6689 11.3241 17.3447 12.9575 17.3447H15.9151C17.5485 17.3447 18.8726 18.6689 18.8726 20.3023V26.9567H10V20.3023Z"
                          fill="#564DED"
                        />
                        <path
                          d="M17.3945 20.3023C17.3945 18.6689 18.7187 17.3447 20.3521 17.3447H23.3096C24.943 17.3447 26.2672 18.6689 26.2672 20.3023V26.9567H17.3945V20.3023Z"
                          fill="white"
                        />
                        <circle
                          cx="14.4341"
                          cy="12.9087"
                          r="2.95754"
                          fill="#564DED"
                        />
                        <circle
                          cx="21.8325"
                          cy="12.9087"
                          r="2.95754"
                          fill="white"
                        />
                      </svg>

                      <div className='pr-3.5'>
                        <p className="text-xl leading-none font-semibold">
                          0
                        </p>
                        <p className="text-xs text-secondary">
                          Followers
                        </p>
                      </div>
                    </div>

                    <div className="flex-1 flex items-center gap-3 w-40 -mr-3.5">
                      <svg
                        width="40"
                        height="39"
                        className="flex-shrink-0 w-10 h-10"
                        viewBox="0 0 40 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.707031"
                          width="38.4568"
                          height="38.4568"
                          rx="5.85212"
                          fill="#DDAA00"
                        />
                        <path
                          d="M14.0703 13.3763L25.7743 9.19629V28.4243H14.0703L14.0703 13.3763Z"
                          fill="white"
                        />
                        <rect
                          x="10.7266"
                          y="25.0811"
                          width="18.392"
                          height="3.344"
                          fill="#DD8400"
                        />
                      </svg>

                      <div className='pr-3.5'>
                        <p className="text-xl leading-none font-semibold">
                          {data?.deal?.length}
                        </p>
                        <p className="text-xs text-secondary">
                          Trofies
                        </p>
                      </div>
                    </div>
                </div>
              </li>
            );
          })}
      </ul>

      <Pagination
        siblingCount={1}
        currentPage={currentPage}
        totalCount={data.data.length}
        pageSize={PageSize}
        onPageChange={(event) => setCurrentPage(event)}
      />
    </>
  );
}
// @ts-ignore-end
