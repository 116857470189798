import React from 'react';
import { usePagination } from './UsePagination';

interface Props {
  onPageChange: (val: number) => void;
  siblingCount: number;
  totalCount: number;
  currentPage: number;
  pageSize: number;
}

export default function Pagination({
  onPageChange,
  siblingCount,
  totalCount,
  currentPage,
  pageSize,
}: Props) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  return (
    <ul className="flex mt-5 justify-end gap-2 items-center">
      {paginationRange &&
        paginationRange.map((pageNumber) => {
          return (
            <li
              key={+pageNumber}
              className={`${
                pageNumber == currentPage
                  ? 'text-white bg-primary border-primary'
                  : 'text-secondary hover:text-primary bg-white border-light-100 hover:border-primary'
              } w-6 h-6 flex items-center justify-center text-xs cursor-pointer text-center border rounded transition-colors duration-300`}
              onClick={() => onPageChange(+pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
    </ul>
  );
}
