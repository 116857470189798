import React, { useState } from 'react';
import IDeal from 'shared/src/model-interfaces/IDeal';
import Shelf from '../../../assets/shapes/Shelf';
import { useNavigate } from 'react-router-dom';
import QrCodeIcon from '../../../assets/icons/QrCodeIcon';

interface Props {
  deals: IDeal[];
}

const DEFAULT_ROW_SIZE = 4;

const getRows = (
  deals: Partial<IDeal>[],
  rowSize: number = DEFAULT_ROW_SIZE
) => {
  let rows = [];
  // let index = 1;

  // while (deals.length < 12) {
  //   deals.push({
  //     id: -index,
  //     image: `trophies/trofy${index}.png`,
  //   });
  //   index++;
  // }

  for (let i = 0; i < deals.length; i += rowSize) {
    rows.push(deals.slice(i, i + rowSize));
  }
  return rows;
};

export default function DealListSection({ deals }: Props) {
  const [selectedDeal, setSelectedDeal] = useState<number | undefined>(
    deals.length && deals[0].id > -1 ? deals[0].id : undefined
  );
  const navigate = useNavigate();

  return (
    <>
      {getRows(deals).map((row, index) => (
        <div key={index}>
          <div className="grid grid-cols-2 gap-x-3 gap-y-5 mb-5 xs:gap-x-5 md:grid-cols-4 xl:grid-cols-4">
            {row.map((deal) => (
              <button
                key={deal.id}
                onClick={() => {
                  if (deal.id! < 0) return;
                  setSelectedDeal(deal.id);
                }}
                className={`group inline-block relative aspect-square p-5 xl:p-8 bg-light border rounded-lg overflow-hidden transition-all duration-300 ${
                  deal.id === selectedDeal
                    ? 'border-primary'
                    : deal.id && deal.id > 0
                    ? 'border-light-300 hover:border-primary'
                    : 'pointer-events-none border-light'
                }`}
              >
                <img
                  key={deal.id}
                  onClick={() => navigate(`deal/${deal.id}`)}
                  src={'https://trofied.s3.amazonaws.com/' + deal.image}
                  alt={deal.image}
                  className={`aspect-square object-center object-contain p-4 transform transition-transform duration-300 ${
                    deal.id === selectedDeal
                      ? 'scale-125'
                      : deal.id && deal.id > 0
                      ? 'group-hover:scale-125'
                      : ''
                  }`}
                />

                <QrCodeIcon className="absolute right-2 bottom-2 w-4 h-4 text-light-550" />
              </button>
            ))}
          </div>

          <div className="hidden md:block w-full mb-9">
            <span className="block h-3.5 bg-gradient-to-b from-light-50 to-white"></span>
            <Shelf className="block w-full" />
          </div>
        </div>
      ))}
    </>
  );
}
