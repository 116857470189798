import React from 'react';

export default function TitleIcon({ className }: { className: string }) {
  return (
    <svg
      className={className}
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Vector-(1)" fill="currentColor" fillRule="nonzero">
          <path
            d="M13.8,9.70561 C12.4202,9.70561 11.1856,10.3239 10.3426,11.2987 L7.42281,9.47789 C7.57231,9.06214 7.66683,8.61981 7.66683,8.15185 C7.66683,7.84611 7.62357,7.55198 7.55758,7.26582 L11.4388,5.45112 C12.0009,6.14606 12.8439,6.59818 13.8002,6.59818 C15.493,6.59818 16.8668,5.20692 16.8668,3.49099 C16.8668,1.77505 15.4928,0.383789 13.8,0.383789 C12.1058,0.383789 10.7334,1.77505 10.7334,3.49099 C10.7334,3.68047 10.7566,3.8634 10.7879,4.04401 L6.91124,5.85712 C6.21275,4.89741 5.09936,4.26773 3.83341,4.26773 C1.71602,4.26796 0,6.00646 0,8.15189 C0,10.2973 1.71602,12.0361 3.83341,12.0361 C4.93535,12.0361 5.92228,11.5581 6.62149,10.8026 L9.53697,12.6203 C9.32121,13.1603 9.20001,13.7483 9.20001,14.3665 C9.20001,16.9412 11.259,19.0274 13.8,19.0274 C16.3397,19.0274 18.4,16.9412 18.4,14.3665 C18.4,11.7918 16.3397,9.70561 13.8,9.70561 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
