import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from '../../components/common/Button';
import logo from '../../assets/svgs/admin_logo.svg';
import { OfficeBuildingIcon, ServerIcon, UsersIcon } from '@heroicons/react/solid';
import CaseIcon from '../../assets/icons/CaseIcon';
import AccountMenu from '../../components/common/AccountMenu';

enum NavButton {
    DASHBOARD = 'dashboard',
    USERS = 'users',
    COMPANIES = 'companies',
    TROFIES = 'trofies',
}

export default function AdminNavBar() {
    const location = useLocation();

    const navigate = useNavigate();
    const isActive = (btn: NavButton): boolean => {
        const re = new RegExp(btn, 'g');
        return !!location.pathname.match(re);
    }

    const navStyle = "bg-transparent text-neutral-400 py-4 pl-1 pr-2 mx-4 hover:text-black hover:border hover:border-b-4 hover:border-x-0 hover:border-t-0 hover:pb-3 hover:border-black";
    const activeNavStyle = "text-black border border-b-4 border-x-0 border-t-0 pl-1 pr-2 pt-4 mx-4 pb-3 border-black";
    
    return (
        <div className="flex justify-around h-20 border-b shadow-sm">
            <h1 className="my-auto text-lg font-bold w-1/3"><Link to="/"><img src={logo} alt="Trofied Logo" className='h-11 mx-auto' /></Link></h1>
            <nav className="w-1/3 flex justify-center">
                <Button 
                    children='Dashboard'
                    icon={<ServerIcon className='h-5 w-5 mr-2 my-auto' />}
                    onClick={() => navigate('/admin/dashboard')}
                    className={isActive(NavButton.DASHBOARD) ? activeNavStyle : navStyle}
                />
                <Button 
                    children='Users'
                    icon={<UsersIcon className='h-5 w-5 mr-2 my-auto' />}
                    onClick={() => navigate('/admin/users')}
                    className={isActive(NavButton.USERS) ? activeNavStyle : navStyle}
                />
                <Button 
                    children='Companies'
                    icon={<OfficeBuildingIcon className='h-5 w-5 mr-2 my-auto' />}
                    onClick={() => navigate('/admin/companies')}
                    className={isActive(NavButton.COMPANIES) ? activeNavStyle : navStyle}
                />
                <Button 
                    children='Trofies'
                    icon={<CaseIcon className='h-[18px] w-[18px] mr-1' />}
                    onClick={() => navigate('/admin/trofies')}
                    className={isActive(NavButton.TROFIES) ? activeNavStyle : navStyle}
                />
            </nav>
            <nav className="my-auto w-1/3">
                <AccountMenu />
            </nav>
        </div>
    )
}