import React from 'react';
import json from './data.json';
import { Player } from '@lottiefiles/react-lottie-player';
import Lottie from 'react-lottie-player';

export default function Checkmark({
  isCheckmarkHovered = false,
  setIsCheckmarkHovered,
}: {
  isCheckmarkHovered: boolean;
  setIsCheckmarkHovered: (isCheckmarkHovered: boolean) => void;
}) {
  return (
    <div>
      <Lottie
        style={{ background: '#7870FF', borderRadius: '15px', margin: 0 }}
        animationData={json}
        loop={true}
        play={isCheckmarkHovered}
        goTo={50}
        className="lottie"
        // onLoopComplete={(e) => {
        //   setIsCheckmarkHovered(false);
        // }}
      />
    </div>
  );
}
