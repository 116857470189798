import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import ButtonMui from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {UserIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";
import Button from "../../common/Button";
import FollowerIcon from "../../../assets/icons/FollowerIcon";
import TrofiesIcon from "../../../assets/icons/TrofiesIcon";
import KeyboardArrowDownIcon from "../../../assets/icons/KeyboardArrowDownIcon";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={2}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MenuSelect() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <ButtonMui
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                    onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon colors={'#7870FF'}/>}
                style={{textTransform: 'none', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400', color: 'black', border: '1px solid #F9F9F9', justifyContent: 'space-between', gap: '35px', alignItems: 'center', padding: '16px'}}
            >
                Sort By
            </ButtonMui>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} disableRipple>
                    <Button
                        icon={<UserIcon className="h-5 w-5 mr-3" />}
                        children={<Link to=''>People</Link>}
                        className="block px-4 py-2 text-sm text-neutral-400 hover:text-purple-color"
                    />
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <Button
                        icon={<FollowerIcon/>}
                        children={<Link to=''>Followers</Link>}
                        className="block px-4 py-2 text-sm text-neutral-400 hover:text-purple-color"
                    />
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <Button
                        icon={<TrofiesIcon/>}
                        children={<Link to=''>Trofies</Link>}
                        className="block px-4 py-2 text-sm text-neutral-400 hover:text-purple-color"
                    />
                </MenuItem>
            </StyledMenu>
        </div>
    );
}