import React, { useState } from 'react';
import IDeal from 'shared/src/model-interfaces/IDeal';
import DealPreview from './DealPreview';
import SampleDealPreview from './SampleDealPreview';
import { useNavigate } from 'react-router-dom';
import DealListSection from './DealListSection';
import CalendarIcon from '../../../assets/animation/Calendar Icon';


interface Props {
  deals: IDeal[];
}

export default function DealSection({ deals }: Props) {
  const [selectedDeal, setSelectedDeal] = useState<number | undefined>(
    deals.length && deals[0].id > -1 ? deals[0].id : undefined
  );
  const navigate = useNavigate();
  
  return (
    <>
      <div className="relative bg-light -mx-[15px] px-[15px] xs:-mx-[20px] xs:px-[20px] md:mx-0 pt-20 pb-12 md:pt-24 lg:pt-12 md:p-12 xl:px-16">
        {selectedDeal ? (
          <DealPreview dealId={selectedDeal} />
        ) : (
          <SampleDealPreview />
        )}
      </div>

      <div className="flex-column py-12 lg:px-[20px] xl:pl-8">
        <DealListSection deals={deals} />
      </div>
    </>
  );
}
