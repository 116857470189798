import React from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import json from "./data.json";
import Lottie from 'react-lottie-player';

interface walletIconProps {
    style: React.CSSProperties;
    className?: string;
    isWalletIconHovered: boolean;
    setIsWalletIconHovered: (isWalletIconHovered: boolean) => void;
  }
  

export default function WalletIcon({
    style,
    className,
    isWalletIconHovered,
    setIsWalletIconHovered,
  }: walletIconProps) {
    return (
        // <Player
        //     style={{cursor: 'pointer', width: '25px', height: '25px', borderRadius: '15px', margin: 0}}
        //     src={json}
        //     loop
        //     autoplay
        // />
        <Lottie
        style={style}
        animationData={json}
        loop={true}
        play={isWalletIconHovered}
      //   goTo={50}
        className={className}
        onLoopComplete={(e) => {
          setIsWalletIconHovered(false);
        }}
      />
    );
}