import React, { useContext, useState, Fragment } from 'react';
import { useQuery } from 'react-query';
import UserProfileApi from '../../api/UserProfileApi';
import EditProfileForm from '../pages/UserProfile/EditProfileForm';
import { Transition } from '@headlessui/react';
import { BsList, BsXLg } from 'react-icons/bs';

import Button from './Button';
import HomeIcon from '../../assets/icons/HomeIcon';
import CaseIcon from '../../assets/icons/CaseIcon';
import ExploreIcon from '../../assets/icons/ExploreIcon';
import ShareIcon from '../../assets/icons/ShareIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import logo from '../../assets/svgs/logo.svg';
import AccountMenu from './AccountMenu';
import SearchIconAnimation from '../../assets/animation/Search Icon';

enum NavButton {
  HOME = 'feed',
  CASE = 'case',
  EXPLORE = 'explore',
  CONTACT = 'contact',
}

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchHovered, setIsSearchHovered] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const location = useLocation();
  const auth = useContext(AuthContext);

  const navigate = useNavigate();
  const isActive = (btn: NavButton): boolean => {
    const re = new RegExp(btn, 'g');
    return !!location.pathname.match(re);
  };

  const { isLoading, data } = useQuery(
    'my-profile',
    UserProfileApi.getMyProfile
  );

  const openSidepanel = () => {
    setIsOpen(true);
  };

  const closeSidepanel = () => {
    setIsOpen(false);
  };

  const navStyle =
    'relative text-sm font-semibold text-secondary hover:text-black select-none px-1 transition-all duration-300 after:absolute after:left-0 after:-bottom-px after:right-0 after:h-0 hover:after:h-1.5 after:bg-black after:transition-all after:duration-300';
  const activeNavStyle =
    'relative text-sm font-semibold text-black select-none px-1 transition-all duration-300 after:absolute after:left-0 after:-bottom-px after:right-0 after:h-1.5 after:bg-black after:transition-all after:duration-300';

  const navStyleMobile =
    'inline-flex items-center text-4.1xl leading-tighter font-semibold select-none text-white hover:text-warning transition-colors duration-300';
  const activeNavStyleMobile =
    'inline-flex items-center text-4.1xl leading-tighter font-semibold select-none text-warning transition-colors duration-300';

  return (
    <>
      <header className="border-b border-light-400 relative">
        <div className="container-2">
          <div className="h-[70px] md:h-[80px] flex justify-between items-center gap-x-4">
            <div className="flex-shrink-0 xl:flex-1">
              <Link to="/">
                <img
                  src={logo}
                  className="h-[45px] sm:h-[50px] xl:h-[50px] w-auto"
                  alt="Logo"
                />
              </Link>
            </div>

            <nav className="flex-grow lg:max-w-[650px] xl:max-w-[700px] 3xl:max-w-[750px] hidden lg:flex self-stretch justify-between gap-x-4 xl:gap-x-6">
              {auth.user ? (
                <>
                  <div className="self-center max-w-[250px]">
                    <div
                      className="relative w-full"
                      onMouseEnter={() => setIsSearchHovered(true)}
                    >
                      <span className="inline-block absolute top-2.5 left-3">
                        <SearchIconAnimation
                          style={{
                            width: '23px',
                            height: '23px',
                            borderRadius: '50px',
                          }}
                          isSearchHovered={isSearchHovered}
                          setIsSearchHovered={setIsSearchHovered}
                        />
                      </span>
                      <input
                        className="field field-sm field-light field-pill pl-11"
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          console.log(`Searched ${e.target.value}!`)
                        }
                      />
                    </div>
                  </div>

                  <Button
                    children="Home"
                    icon={<HomeIcon className="h-[20px] w-[20px] mr-1" />}
                    onClick={() => navigate('/coming-soon')}
                    className={
                      isActive(NavButton.HOME) ? activeNavStyle : navStyle
                    }
                  />

                  <Button
                    children="Case"
                    icon={<CaseIcon className="h-[18px] w-[18px] mr-1" />}
                    onClick={() => navigate('/case')}
                    className={
                      isActive(NavButton.CASE) ? activeNavStyle : navStyle
                    }
                  />

                  <Button
                    children="Explore"
                    icon={<ExploreIcon className="h-[19px] w-[19px] mr-1" />}
                    onClick={() => navigate('/explore')}
                    className={
                      isActive(NavButton.EXPLORE) ? activeNavStyle : navStyle
                    }
                  />

                  <Button
                    children="Contact"
                    icon={<ShareIcon className="h-[19px] w-[19px] mr-1" />}
                    onClick={() => navigate('/contact')}
                    className={
                      isActive(NavButton.CONTACT) ? activeNavStyle : navStyle
                    }
                  />
                </>
              ) : (
                <></>
                // <a className="cursor-pointer bg-white flex justify-center items-center bg-[#FCFCFC] rounded-full w-[44px] h-[44px] text-black hover:text-gray-700">
                //   <i className="my-auto align-middle ">
                //     <svg
                //       width="17"
                //       height="17"
                //       viewBox="0 0 17 17"
                //       fill="none"
                //       xmlns="http://www.w3.org/2000/svg"
                //     >
                //       <path
                //         d="M15.9032 14.547L12.7921 11.4366C13.735 10.2025 14.2521 8.7057 14.2523 7.12622C14.2523 5.22279 13.5108 3.43316 12.1644 2.08724C10.8182 0.741329 9.02839 0 7.12432 0C5.22049 0 3.43048 0.741329 2.08428 2.08724C-0.694762 4.86594 -0.694762 9.38697 2.08428 12.1652C3.43048 13.5113 5.22049 14.2527 7.12432 14.2527C8.70413 14.2525 10.2013 13.7355 11.4356 12.7928L14.5467 15.9032C14.7338 16.0905 14.9795 16.1842 15.2249 16.1842C15.4704 16.1842 15.716 16.0905 15.9032 15.9032C16.2779 15.5288 16.2779 14.9214 15.9032 14.547ZM3.44079 10.809C1.40978 8.77841 1.41002 5.47427 3.44079 3.44346C4.42467 2.46001 5.73293 1.91818 7.12432 1.91818C8.51595 1.91818 9.82397 2.46001 10.8079 3.44346C11.7917 4.42714 12.3337 5.73512 12.3337 7.12622C12.3337 8.51756 11.7917 9.82531 10.8079 10.809C9.82397 11.7927 8.51595 12.3345 7.12432 12.3345C5.73293 12.3345 4.42467 11.7927 3.44079 10.809Z"
                //         fill="black"
                //       />
                //     </svg>
                //   </i>
                // </a>
              )}
            </nav>

            <div className="flex-shrink-0 xl:flex-1 justify-end flex self-stretch gap-4 sm:gap-6">
              {auth.user ? (
                <AccountMenu
                  onEdit={() => setEditModalOpen(true)}
                  walletAddress={data?.data?.profile.walletAddress}
                />
              ) : (
                <div className="hidden lg:flex items-center gap-x-3">
                  <Button
                    children="Log In"
                    onClick={() => navigate('/login')}
                    className="btn btn-black btn-sm lg:w-32"
                  />

                  <Button
                    className="btn btn-outline-black btn-sm lg:w-32"
                    children={'Register'}
                    onClick={() => navigate('/signup')}
                  />
                </div>
              )}

              <div className="flex items-center lg:hidden">
                <button
                  onClick={() => openSidepanel()}
                  type="button"
                  className="btn btn-primary btn-sm btn-icon btn-circle"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <BsList />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Transition show={isOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="lg:hidden fixed z-[1040] inset-0 bg-black bg-opacity-50"
            onClick={() => closeSidepanel()}
          ></div>
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className="lg:hidden bg-black fixed z-[1045] top-0 right-0 bottom-0 w-[500px] max-w-full flex flex-col"
            id="mobile-menu"
          >
            <button
              onClick={() => closeSidepanel()}
              type="button"
              className="absolute top-5 right-5 btn btn-danger btn-sm btn-icon btn-circle"
            >
              <BsXLg className="bs-xlg" />
            </button>

            <div className="py-12 px-16 flex flex-col items-start overflow-y-auto flex-grow">
              <div className="my-auto">
                <div className="flex flex-col space-y-16">
                  {auth.user ? (
                    <>
                      <Button
                        children="Home"
                        icon={<HomeIcon className="w-[40px] h-[40px] mr-4" />}
                        // onClick={() => navigate('feed')}
                        onClick={() => {
                          navigate('/coming-soon');
                          closeSidepanel();
                        }}
                        className={
                          isActive(NavButton.HOME)
                            ? activeNavStyleMobile
                            : navStyleMobile
                        }
                      />
                      <Button
                        children="Case"
                        icon={<CaseIcon className="w-[40px] h-[34px] mr-4" />}
                        onClick={() => {
                          navigate('/case');
                          closeSidepanel();
                        }}
                        className={
                          isActive(NavButton.CASE)
                            ? activeNavStyleMobile
                            : navStyleMobile
                        }
                      />
                      <Button
                        children="Explore"
                        icon={
                          <ExploreIcon className="w-[40px] h-[39px] mr-4" />
                        }
                        onClick={() => {
                          navigate('/explore');
                          closeSidepanel();
                        }}
                        className={
                          isActive(NavButton.EXPLORE)
                            ? activeNavStyleMobile
                            : navStyleMobile
                        }
                      />
                      <Button
                        children="Contact"
                        icon={<ShareIcon className="w-[40px] h-[36px] mr-4" />}
                        onClick={() => {
                          navigate('/contact');
                          closeSidepanel();
                        }}
                        className={
                          isActive(NavButton.CONTACT)
                            ? activeNavStyleMobile
                            : navStyleMobile
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        children="Log In"
                        onClick={() => {
                          navigate('/login');
                          closeSidepanel();
                        }}
                        className={navStyleMobile}
                      />
                      <Button
                        children={'Register'}
                        onClick={() => {
                          navigate('/signup');
                          closeSidepanel();
                        }}
                        className={navStyleMobile}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>

      {!data || !data.data ? (
        <></>
      ) : (
        <>
          <EditProfileForm
            open={editModalOpen}
            setOpen={setEditModalOpen}
            userId={data.data.id}
            initialValues={data.data.profile}
          />
        </>
      )}
    </>
  );
}

export default Navbar;
