import React from 'react';

export default function TrofiesIcon() {
    return (
        <svg className="mr-3" width="20px" height="20px" viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-701" fillRule="nonzero">
                    <rect id="Rectangle-path" fill="#DDAA00" x="0" y="0" width="46" height="46" rx="5.09406"></rect>
                    <polygon id="Shape" fill="#FFFFFF" points="15.9961 16.0009 29.9958 11.001 29.9958 34.0005 15.9961 34.0005"></polygon>
                    <rect id="Rectangle-path" fill="#DD8400" x="12" y="30" width="21.9996" height="3.99992"></rect>
                </g>
            </g>
        </svg>
    )
}