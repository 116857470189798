import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/svgs/logo.svg';

const navigation = {
  Company: [
    { name: 'About Us', href: '/coming-soon' },
    { name: 'Brand Kit', href: '/coming-soon' },
  ],

  Contact: [
    { name: 'Press Inquiries', href: '/contact' },
    { name: 'Data Requests', href: '/contact' },
    { name: 'Design Requests', href: '/contact' },
  ],

  Resources: [
    { name: 'Blockchain', href: '#' },
    { name: 'Non-Fungible Tokens (NFTs)', href: '#' },
  ],
  Legal: [
    { name: 'Terms & Conditions', href: '/terms-and-conditions' },
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Disclaimer', href: '/disclaimer' },
  ],
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/trofied',
      className: 'text-black hover:text-primary',
      target: '_blank',
      icon: (props: any) => (
        <svg className="flex-shrink-0 w-7 h-7" fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Facebook',
      href: '#!',
      target: '',
      className: 'text-black hover:text-secondary cursor-default',
      icon: (props: any) => (
        <svg className="flex-shrink-0 w-7 h-6" {...props} viewBox="0 0 11 20" fill="none">
          <path
            d="M3.73222 11.1206H1.45453C1.09707 11.1206 0.96875 10.9894 0.96875 10.6282C0.96875 9.69711 0.96875 8.7668 0.96875 7.83727C0.96875 7.47833 1.10394 7.34258 1.45682 7.34258H3.73222V5.32935C3.70521 4.42503 3.9213 3.5301 4.35778 2.73861C4.81152 1.93954 5.53859 1.33293 6.40403 1.03138C6.96568 0.826182 7.55931 0.723331 8.15697 0.727671H10.4095C10.7325 0.727671 10.8677 0.870323 10.8677 1.18784V3.8131C10.8677 4.14212 10.7303 4.27326 10.4095 4.27326C9.79306 4.27326 9.17666 4.27326 8.56256 4.29857C7.94845 4.32388 7.6139 4.60459 7.6139 5.24882C7.60016 5.93907 7.6139 6.61552 7.6139 7.31958H10.2605C10.6363 7.31958 10.7646 7.44842 10.7646 7.82806C10.7646 8.7484 10.7646 9.67333 10.7646 10.6029C10.7646 10.9779 10.6455 11.0953 10.2674 11.0976H7.60016V18.5845C7.60016 18.9848 7.47642 19.1114 7.08229 19.1114H4.21342C3.86741 19.1114 3.73222 18.9756 3.73222 18.6282V11.1206Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#!',
      target: '',
      className: 'text-black hover:text-secondary cursor-default',
      icon: (props: any) => (
        <svg className="flex-shrink-0 w-7 h-7" fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'Linkedin',
      href: '#!',
      target: '',
      className: 'text-black hover:text-secondary cursor-default',
      icon: (props: any) => (
        <svg className="flex-shrink-0 w-7 h-6 -mt-1" {...props} viewBox="0 0 18 17" fill="none">
          <path
            d="M17.0021 16.9999V10.7736C17.0021 7.71352 16.3433 5.37598 12.7733 5.37598C11.052 5.37598 9.90445 6.31099 9.43694 7.20351H9.39444V5.65223H6.01562V16.9999H9.54319V11.3686C9.54319 9.88106 9.81945 8.45728 11.647 8.45728C13.4533 8.45728 13.4745 10.1361 13.4745 11.4536V16.9787H17.0021V16.9999Z"
            fill="currentColor"
          />
          <path
            d="M0.277344 5.65332H3.80491V17.001H0.277344V5.65332Z"
            fill="currentColor"
          />
          <path
            d="M2.04004 0C0.913767 0 0 0.913767 0 2.04004C0 3.16631 0.913767 4.10133 2.04004 4.10133C3.16631 4.10133 4.08008 3.16631 4.08008 2.04004C4.08008 0.913767 3.16631 0 2.04004 0Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {

  return (
    <>
      <footer
        className="bg-light pt-16 pb-12 md:pt-14 md:pb-16"
        aria-labelledby="footer-heading"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>

        <div className="container">
          <div className="grid grid-cols-12 gap-x-5 gap-y-12">
            <div className="col-span-full lg:col-span-6 flex flex-col items-center lg:items-start">
              <Link to="/">
                <img src={logo} className="h-[50px] w-auto" />
              </Link>

              <div className="flex items-center gap-5 mt-6 lg:mt-11">
                {navigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={`inline-flex items-center justify-center no-underline hover:no-underline transition-all duration-300 ${item.className}`}
                    target={item.target}
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>

            <div className="col-span-6 md:col-span-4 lg:col-span-2">
              <h3 className="text-base font-semibold">Company</h3>

              <span className="inline-block w-8 h-1 mt-3 mb-4 bg-primary"></span>

              <ul role="list" className="flex flex-col gap-4">
                {navigation.Company.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-sm leading-loose text-secondary hover:text-black no-underline hover:underline transition-all duration-300"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-span-6 md:col-span-4 lg:col-span-2">
              <h3 className="text-base font-semibold">Contact</h3>

              <span className="inline-block w-8 h-1 mt-3 mb-4 bg-primary"></span>

              <ul role="list" className="flex flex-col gap-4">
                {navigation.Contact.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-sm leading-loose text-secondary hover:text-black no-underline hover:underline transition-all duration-300"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-span-6 md:col-span-4 lg:col-span-2">
              <h3 className="text-base font-semibold">Legal</h3>

              <span className="inline-block w-8 h-1 mt-3 mb-4 bg-primary"></span>

              <ul role="list" className="flex flex-col gap-4">
                {navigation.Legal.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-sm leading-loose text-secondary hover:text-black no-underline hover:underline transition-all duration-300"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <div className="bg-white py-5 md:py-6">
        <div className="container">
          <p className="text-sm leading-loose text-secondary text-center">
            &copy; {new Date().getFullYear()} Trofied All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}
