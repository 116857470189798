import React, { useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowRightIcon from '../../../assets/icons/ArrowRightIcon'
import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon'

import FavoriteIcon from "../../../assets/icons/FavoriteIcon";
import MessageIcon from "../../../assets/icons/MessageIcon";
import {useQuery} from "react-query";
import UsersApi from "../../../api/UsersApi";
import IUser from "shared/src/model-interfaces/IUser";
import FavoriteIconAnimated from "../../../assets/animation/Favorite Icon";

type Props = {
    users: IUser
};

    const Slide = ({users}: Props) => {
    return (
        <>
            {
                users?.deal && users?.deal.length > 0 ?
                    <div className='w-[250px] cursor-pointer gap-[25px] h-[385px] bg-white'>
                        <div className='mb-[36px] justify-center flex gap-[15px] items-center'>
                            {users?.profile?.profileImage ? (
                                <img
                                    alt="User Profile"
                                    src={'https://trofied.s3.amazonaws.com/' + users?.profile?.profileImage }
                                    className="object-cover aspect-square rounded-full align-center h-[65px] w-[65px]"
                                />
                            ) : (
                                <span className="inline-block h-[65px] w-[65px] rounded-full overflow-hidden bg-neutral-100">
                                    <svg
                                        className="h-full w-full text-neutral-300"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                    </svg>
                                </span>
                            )}

                            <div className='flex flex-col items-center gap-[12px]'>
                                <div className='flex gap-[12px] items-center'>
                                    <FavoriteIconAnimated/>
                                    <div>335</div>
                                </div>
                                <div className='flex gap-[12px] items-center'>
                                    <MessageIcon/>
                                    <div>125</div>
                                </div>
                            </div>
                        </div>

                        <div className="relative bg-[#FCFCFC] rounded-[10px] border-[1px] p-[50px] border-solid border-[#FCFCFC] w-[250px] h-[216.74px]">
                            <img
                                alt=""
                                src={'https://trofied.s3.amazonaws.com/' + users?.deal[0]?.image }
                                className="aspect-square object-center object-contain w-full h-full"
                            />
                            <svg width="19" height="19" viewBox="0 0 19 19" className='cursor-pointer absolute right-[20px] bottom-[20px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3934 5.96012C17.0277 5.96012 16.7311 5.66359 16.7311 5.29782V2.78515C16.7311 2.49352 16.4939 2.25624 16.2023 2.25624H13.6896C13.3239 2.25624 13.0273 1.9597 13.0273 1.59394C13.0273 1.22817 13.3239 0.931641 13.6896 0.931641H16.2023C17.2243 0.931641 18.0557 1.76313 18.0557 2.78515V5.29782C18.0557 5.66359 17.7592 5.96012 17.3934 5.96012Z" fill="#CDCDCD"/>
                                <path d="M1.51386 5.96012C1.1481 5.96012 0.851562 5.66359 0.851562 5.29782V2.78515C0.851562 1.76313 1.68301 0.931641 2.70507 0.931641H5.21766C5.58342 0.931641 5.87995 1.22817 5.87995 1.59394C5.87995 1.9597 5.58342 2.25624 5.21766 2.25624H2.70507C2.41344 2.25624 2.17616 2.49352 2.17616 2.78515V5.29782C2.17616 5.66359 1.87967 5.96012 1.51386 5.96012Z" fill="#CDCDCD"/>
                                <path d="M5.21766 18.1435H2.70507C1.68306 18.1435 0.851562 17.312 0.851562 16.29V13.7775C0.851562 13.4118 1.1481 13.1152 1.51386 13.1152C1.87963 13.1152 2.17616 13.4118 2.17616 13.7775V16.29C2.17616 16.5816 2.41339 16.8189 2.70507 16.8189H5.21766C5.58342 16.8189 5.87995 17.1154 5.87995 17.4812C5.87995 17.8469 5.58342 18.1435 5.21766 18.1435Z" fill="#CDCDCD"/>
                                <path d="M16.2023 18.1435H13.6896C13.3239 18.1435 13.0273 17.8469 13.0273 17.4812C13.0273 17.1154 13.3239 16.8189 13.6896 16.8189H16.2023C16.4939 16.8189 16.7311 16.5816 16.7311 16.29V13.7775C16.7311 13.4118 17.0277 13.1152 17.3934 13.1152C17.7592 13.1152 18.0557 13.4118 18.0557 13.7775V16.29C18.0557 17.312 17.2242 18.1435 16.2023 18.1435Z" fill="#CDCDCD"/>
                                <path d="M7.40317 8.53263H4.88754C4.30339 8.53263 3.82812 8.05745 3.82812 7.47335V4.95785C3.82812 4.3737 4.30339 3.89844 4.88754 3.89844H7.40317C7.98732 3.89844 8.46258 4.3737 8.46258 4.95785V7.47335C8.46263 8.05741 7.98736 8.53263 7.40317 8.53263ZM5.15277 7.20803H7.13803V5.22303H5.15277V7.20803Z" fill="#CDCDCD"/>
                                <path d="M7.40317 15.1869H4.88754C4.30339 15.1869 3.82812 14.7117 3.82812 14.1275V11.6121C3.82812 11.028 4.30339 10.5527 4.88754 10.5527H7.40317C7.98732 10.5527 8.46258 11.028 8.46258 11.6121V14.1275C8.46263 14.7117 7.98736 15.1869 7.40317 15.1869ZM5.15277 13.8623H7.13803V11.8773H5.15277V13.8623Z" fill="#CDCDCD"/>
                                <path d="M14.0475 15.1869H11.5321C10.9479 15.1869 10.4727 14.7117 10.4727 14.1275V11.6121C10.4727 11.028 10.9479 10.5527 11.5321 10.5527H14.0475C14.6317 10.5527 15.1069 11.028 15.1069 11.6121V14.1275C15.1069 14.7117 14.6317 15.1869 14.0475 15.1869ZM11.7973 13.8623H13.7823V11.8773H11.7973V13.8623Z" fill="#CDCDCD"/>
                                <path d="M14.0475 8.53263H11.5321C10.9479 8.53263 10.4727 8.05745 10.4727 7.47335V4.95785C10.4727 4.3737 10.9479 3.89844 11.5321 3.89844H14.0475C14.6317 3.89844 15.1069 4.3737 15.1069 4.95785V7.47335C15.1069 8.05741 14.6317 8.53263 14.0475 8.53263ZM11.7973 7.20803H13.7823V5.22303H11.7973V7.20803Z" fill="#CDCDCD"/>
                            </svg>
                        </div>
                    </div>
                : null
            }
        </>
    );
};

export default function CarouselUserDeal() {
    const {
        data,
    } = useQuery('list-users', () => UsersApi.listUsers({}));
    const [sliderRef, setSliderRef] = useState<any>(null)

    const slickSettings = {
        infinite: true,
        dots: false,
        pause: true,
        autoplay: false,
        centerMode: true,
        centerPadding: '60px',
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        nextArrow: <ArrowRightIcon onClick={sliderRef?.slickNext()}/>,
        prevArrow: <ArrowLeftIcon onClick={sliderRef?.slickPrev()}/>,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },

            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },

            {
                breakpoint: 767,
                settings: {
                    infinite: false,
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },

            {
                breakpoint: 480,
                settings: {
                    infinite: false,
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div className=''>
            <div className='relative'>
                <Slider ref={setSliderRef} {...slickSettings}>
                    { data?.data?.map(data => <Slide users={data}/>) }
                </Slider>
            </div>

            <div
                className='w-full h-[35px]'
                style={{background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.1) 51.47%, rgba(0, 0, 0, 0) 95.35%)'}}
            />
        </div>
    );
}