import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import TextField from '../../common/TextInput';
import SelectInput from '../../common/SelectInput';
import states from '../../../lib/constants/States.constant';
import ErrorMessage from '../../common/ErrorMessage';
import UserProfileApi from '../../../api/UserProfileApi';
import Modal from '../../common/Modal';
import ICompany from 'shared/src/model-interfaces/ICompany';
import FileDrop from '../../common/FileDrop';
import GetConnectedWallet from '../../../utils/WalletFunctions/GetConnectedWallet';
import DataApi from '../../../api/DataApi';
interface IEditProfileForm {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  currentCompanyId?: number;
  schoolId?: number;
  walletAddress?: string;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  profileImage: Yup.string().nullable(),
  jobTitle: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  countryCode: Yup.string().nullable(),
  currentCompanyId: Yup.number().nullable(),
  schoolId: Yup.number().nullable(),
  walletAddress: Yup.string().nullable(),
});

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  userId: number;
  initialValues: {
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
    city?: string;
    state?: string;
    countryCode?: string;
    countryName?: string;
    currentCompanyId?: number;
    schoolId?: number;
    schoolName?: string;
    company?: ICompany;
    walletAddress?: string;
  };
}

export default function EditProfileForm({
  open,
  userId,
  setOpen,
  initialValues,
}: Props) {
  const [school, setSchool] = useState('');
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const editProfile = useMutation(UserProfileApi.updateMyProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries('my-profile');
      setOpen(false);
    },
    onError: (err) => {
      console.log(err);
      setSubmitError('Something went wrong, please try again.');
    },
  });

  const connectWallet = async () => {
    const { success, address, status } = await GetConnectedWallet();
    if (success) {
      return address;
    }
  };

  const getSchools = async (query: string) => {
    const response = await UserProfileApi.getSchools(query);
    if (!response.data) {
      return [];
    }

    return response.data.map((school) => {
      return {
        value: school.id,
        label: school.name,
      };
    });
  };

  const getCountries = async (query: string) => {
    const response = await DataApi.getCountries(query);
    if (!response.data) {
      return [];
    }

    return response.data.map((country) => {
      return {
        value: country.code,
        label: country.name,
      };
    });
  };

  const getCompanies = async (query: string) => {
    const response = await UserProfileApi.getCompanies(query);
    if (!response.data) {
      return [];
    }

    return response.data.map((company: ICompany) => {
      return {
        value: company.id,
        label: company.name,
      };
    });
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <ErrorMessage error={submitError} />
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            editProfile.mutate(values);
            // actions.setSubmitting(false);
            // setSubmitError(err && err.message ? err.message : 'Something went wrong, please try again.');
          }}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {(props: FormikProps<IEditProfileForm>) => {
            const {
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
            } = props;

            return (
              <Form>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-t-lg">
                  <h1 className="text-2xl mb-6 font-semibold">Edit Profile</h1>

                  <div className="flex flex-row justify-between w-full">
                    <TextField
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.firstName && touched.firstName)}
                      errorMessage={errors.firstName}
                      value={values.firstName}
                      parentClass={'basis-1/2'}
                    />
                    <TextField
                      label="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.lastName && touched.lastName)}
                      errorMessage={errors.lastName}
                      value={values.lastName}
                      parentClass={'basis-1/2 ml-4'}
                    />
                  </div>

                  <FileDrop
                    label="Profile Image"
                    uploadPrefix={`users/${userId}`}
                    uploadName="profileImage"
                    onUpload={(uploadPath: string) =>
                      setFieldValue('profileImage', uploadPath)
                    }
                  />

                  <TextField
                    label="Job Title"
                    name="jobTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.jobTitle && touched.jobTitle)}
                    errorMessage={errors.jobTitle}
                    value={values.jobTitle}
                  />

                  <div className="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
                    <TextField
                      label="City"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.city && touched.city)}
                      errorMessage={errors.city}
                      value={values.city}
                      parentClass={'px-2 md:w-4/5'}
                    />

                    <SelectInput
                      label="State"
                      name="state"
                      options={states.map((s) => {
                        return {
                          label: s,
                          value: s,
                        };
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.state && touched.state)}
                      errorMessage={errors.state}
                      value={values.state}
                      parentClass={'px-2 md:w-1/5'}
                    />
                  </div>

                  <TextField
                    label="Country"
                    name="countryCode"
                    onChange={(option: any) =>
                      setFieldValue('countryCode', option.value)
                    }
                    onBlur={handleBlur}
                    error={!!(errors.countryCode && touched.countryCode)}
                    errorMessage={errors.countryCode}
                    value={values.countryCode}
                    asyncSelect={true}
                    loadOptions={getCountries}
                    defaultInputValue={initialValues.countryName}
                    defaultValue={
                      initialValues.countryCode
                        ? initialValues.countryCode.toString()
                        : undefined
                    }
                  />

                  <TextField
                    label="Company"
                    name="currentCompanyId"
                    onChange={(option: any) =>
                      setFieldValue('currentCompanyId', option.value)
                    }
                    onBlur={handleBlur}
                    error={
                      !!(errors.currentCompanyId && touched.currentCompanyId)
                    }
                    errorMessage={errors.currentCompanyId}
                    value={values.currentCompanyId}
                    asyncSelect={true}
                    loadOptions={getCompanies}
                    defaultInputValue={
                      initialValues.company
                        ? initialValues.company.name
                        : undefined
                    }
                    defaultValue={
                      initialValues.currentCompanyId
                        ? initialValues.currentCompanyId.toString()
                        : undefined
                    }
                  />

                  <TextField
                    label="Alma Mater"
                    name="schoolId"
                    onChange={(option: any) =>
                      setFieldValue('schoolId', option.value)
                    }
                    onBlur={handleBlur}
                    error={!!(errors.schoolId && touched.schoolId)}
                    errorMessage={errors.schoolId}
                    value={values.schoolId}
                    asyncSelect={true}
                    loadOptions={getSchools}
                    defaultInputValue={initialValues.schoolName}
                    defaultValue={
                      initialValues.schoolId
                        ? initialValues.schoolId.toString()
                        : undefined
                    }
                  />

                  <div className="flex flex-row w-full">
                    <TextField
                      label="Crypto Wallet"
                      name="walletAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.walletAddress && touched.walletAddress)}
                      errorMessage={errors.walletAddress}
                      value={values.walletAddress}
                      isReadOnly={true}
                      parentClass={'md:w-4/5 mr-2'}
                    />

                    {/* <button
                      type="button"
                      className="self-start mt-6 rounded-md bg-white font-medium text-primary py-2.5 px-4 hover:bg-light-300 transition-colors duration-300"
                      onClick={async () =>
                        setFieldValue('walletAddress', await connectWallet())
                      }
                    >
                      Connect Metamask
                    </button> */}

                    <Link
                      to="/wallet"
											onClick={() => setOpen(false)}
                      className="self-start mt-6 rounded-md bg-white font-medium text-primary py-2.5 px-4 hover:bg-light-300 transition-colors duration-300"
                    >
                      Connect 
                    </Link>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-300"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-300"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}
