import React from 'react';

export default function CaseIcon({ className }: { className: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 19 20" fill="currentColor">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none">
                <g id="Group-637" fill="currentColor">
                    <polygon id="Shape" points="3.433148 4.27294054 15.4486286 0 15.4486286 19.6555676 3.433148 19.6555676"></polygon>
                    <rect id="Rectangle-path" x="0" y="16.2372973" width="18.8815486" height="3.41835676"></rect>
                </g>
            </g>
        </svg>
    )
}