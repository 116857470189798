import React from 'react';

interface Props {
    error?: string;
}

export default function ErrorMessage({ error }: Props) {
    return (
        <div>
            {error && <p className="bg-red-200 text-red-900 p-3 my-3 text-sm border rounded border-red-300">{error}</p>}
        </div>
    )
}